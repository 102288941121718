import { Empty } from "antd";
import React from "react";
import Text from "../Text";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export default function EmptyComponent({ image, text, loading }) {
   return loading ? (
      <div className="py-5 my-5 text-center">
         <Spin indicator={antIcon} />
      </div>
   ) : (
      <Empty
         className="py-5 my-5"
         image={image ?? Empty.PRESENTED_IMAGE_SIMPLE}
         description={<Text>{text}</Text>}
      />
   );
}
