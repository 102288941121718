import React from "react";
import EmptyComponent from "../../../components/EmptyComponent/EmptyComponent";
import Header from "../../../components/header/Header";
import AppContext from "../../../contexts/AppContext";
import AcademyData from "./AcademyData";

export default function Academy() {
   const { appState } = React.useContext(AppContext);
   return (
      <div>
         <Header text="Academy" />

         {appState.details.academies.length !== 0 ? (
            <AcademyData academyId={appState.details.academies[0].id} />
         ) : (
            <EmptyComponent text="You are not currently enrolled in an academy." />
         )}
      </div>
   );
}
