import React from "react";
import { Comment, Avatar } from "antd";
import styles from "../popups.module.css";
import Text from "../../Text";

export default function CommentItem({ item }) {
   return (
      <Comment
         actions={[]}
         author={
            <Text wrap textClass={styles.post_title} primary bold>
               {item?.user_obj?.username}
            </Text>
         }
         avatar={
            <Avatar
               src={item?.user_obj?.picture}
               alt={item?.user_obj?.username}
            />
         }
         content={
            <Text wrap textClass={styles.tags} fSize={14}>
               {item?.text}
            </Text>
         }
      />
   );
}
