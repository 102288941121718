import React from "react";
import { Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import authss from "../../assets/imgs/AuthScreenShot.png";
import styles from "../../styles/auth.module.css";
import Login from "./Login";
import Signup from "./Signup";
import ForgotPassword from "./Recover/ForgotPassword";
import PasswordVerification from "./Recover/PasswordVerification";
import NewPassword from "./Recover/NewPassword";
import PasswordSuccess from "./Recover/PasswordSuccess";
import SignupCode from "./SignupCode";
import SignupSuccess from "./SignupSuccess";

export default function Auth() {
   return (
      <Row>
         <Col className={styles.formCol}>
            <Row className="justify-content-center">
               <Col lg={9}>
                  <Container>
                     <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="signup" element={<Signup />} />

                        <Route path="signup-code" element={<SignupCode />} />
                        <Route
                           path="signup-success"
                           element={<SignupSuccess />}
                        />

                        {/* password recover */}
                        <Route
                           path="password-recover"
                           element={<ForgotPassword />}
                        />
                        <Route
                           path="password-verification"
                           element={<PasswordVerification />}
                        />
                        <Route
                           path="password-reset"
                           element={<NewPassword />}
                        />
                        <Route
                           path="password-success"
                           element={<PasswordSuccess />}
                        />

                        <Route path="*" element={<Login />} />
                     </Routes>
                  </Container>
               </Col>
            </Row>
         </Col>
         <Col className={styles.bgPicture}>
            <img
               src={authss}
               style={{
                  alignSelf: "center",
               }}
               alt="scoutx brand logo"
               className={styles.logo}
            />
         </Col>
      </Row>
   );
}
