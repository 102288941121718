export class Network {
   constructor() {
      this.token = "";
   }
   static async fetch(url, init, addAuth) {
      this.token = localStorage.getItem("scout_token");

      const response = await fetch(url, {
         mode: "cors",
         ...init,
         headers: Network.getHeaders(init.headers, addAuth),
      });
      let promise;
      if (response.status !== 200 && response.status !== 201) {
         promise = Network.handleErrorsBasedOnStatus(response);
      } else {
         promise = response.json();
      }
      return promise;
   }

   static getHeaders(originalHeaders, addAuth) {
      let headers = {
         "Accept-Language": "en",
      };
      if (addAuth) {
         headers.Authorization = `Token ${this.token}`;
      }
      headers = {
         ...headers,
         ...originalHeaders,
      };

      return headers;
   }
   static handleErrorsBasedOnStatus(response) {
      let promise;

      switch (response.status) {
         case 400:
            promise = response.json().then((data) => {
               return Promise.reject(data);
            });
            break;
         case 422:
            promise = response.json().then((data) => {
               return Promise.reject(data);
            });
            break;
         case 429:
            promise = response.json().then((data) => {
               return Promise.reject(data);
            });
            break;
         case 401:
         case 403:
            promise = response.json().then((data) => {
               return Promise.reject(data);
            });
            break;
         default:
            promise = response.json().then((data) => {
               return Promise.reject(data);
            });
      }

      return promise.catch((error) => {
         return Promise.reject(error);
      });
   }
}
