import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./PlayerDetails.module.css";

export default function PlayerDetails({ label, value }) {
   return (
      <Row className="align-items-center my-2">
         <div className={styles.line}></div>
         <Col>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
         </Col>
      </Row>
   );
}
