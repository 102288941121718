const appState = {
   data: {},
   details: {},
   isSignout: true,
   userToken: null,
   userType: null,
};

export const AppReducer = (state = appState, action) => {
   switch (action.type) {
      case "SIGN_IN":
         return {
            ...state,
            isSignout: false,
            userToken: action.payload.access,
            userType: action.payload.user_type,
         };
      case "SIGN_OUT":
         return {
            ...state,
            isSignout: true,
            userToken: null,
            userType: null,
            data: {},
         };
      case "SET_DATA":
         return {
            ...state,
            data: action.payload,
         };
      case "SET_DETAILS":
         return {
            ...state,
            details: action.payload,
         };
      default:
         return state;
   }
};
