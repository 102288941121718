import React from "react";
import { Input } from "antd";
import styles from "./inputs.module.css";
import { BiSearch } from "react-icons/bi";
const { Search } = Input;

const suffix = (
   <BiSearch
      style={{
         fontSize: 20,
         color: "#ED6623",
      }}
   />
);

function SearchInput({ style, onSearch }) {
   return (
      <Search
         placeholder="Search"
         bordered={false}
         enterButton={suffix}
         className={styles.input}
         onSearch={onSearch}
         style={{
            minWidth: 300,
            width:'100%',
            maxWidth:350,
            height: 40,
            marginBlock: 8,
            boxShadow: "none",
            ...style,
         }}
      />
   );
}

export default SearchInput;
