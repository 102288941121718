import * as React from "react";
import {
   Box,
   Card,
   CardActionArea,
   CardContent,
   CardMedia,
} from "@mui/material";
import Text from "../../Text";
import DefaultUser from "../../../assets/images/defaultUser.png";
import DefaultCoach from "../../../assets/images/defaultCoach.png";
import DefaultTeam from "../../../assets/images/defaultTeam.jpg";
import { useNavigate } from "react-router-dom";

export default function AcademyCard({ item, type }) {
   const navigate = useNavigate();

   const handleClick = () => {
      switch (type) {
         case "Players":
            navigate(`/app/players/${item.id}`);
            break;
         case "Coaches":
            navigate(`/app/coaches/${item.id}`);
            break;
         default:
            break;
      }
   };

   return (
      <Card
         sx={{
            height: 80,
            boxShadow: "0px 0px 3px #00000029",
            borderRadius: 1,
            margin: 2,
            "&:hover": {
               boxShadow: "0px 0px 9px #00000029",
            },
         }}>
         <CardActionArea
            onClick={() => handleClick()}
            sx={{
               display: "flex",
               justifyContent: "start",
               height: "100%",
            }}>
            <CardMedia
               component="img"
               sx={{ width: 90, height: "100%" }}
               image={
                  item.picture ??
                  (type === "Players"
                     ? DefaultUser
                     : type === "Coaches"
                     ? DefaultCoach
                     : DefaultTeam)
               }
               alt={item.username ?? item.name}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
               <CardContent sx={{ flex: "1 0 auto" }}>
                  <Text bold secondary fSize={18}>
                     {item.username ?? item.name}
                  </Text>
                  <Text secondary fSize={18}>
                     {item.position}
                  </Text>
                  <Text secondary fSize={18}>
                     {item.team}
                  </Text>
               </CardContent>
            </Box>
         </CardActionArea>
      </Card>
   );
}
