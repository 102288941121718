import { useState, useEffect, useContext } from "react";
import AppContext from "../contexts/AppContext";

export default function useIsAuthenticatedUser(id) {
   const [isAuthenticated, setIsAuthenticated] = useState(null);
   const { appState } = useContext(AppContext);

   useEffect(() => {
      setIsAuthenticated(String(id) === String(appState.data?.id));
   });

   return isAuthenticated;
}
