import React from "react";
import { Input, Form, Tooltip } from "antd";
import styles from "./inputs.module.css";

function InputComp(props) {
   return (
      <Tooltip
         title={props.placeholder}
         placement="bottom">
         <Form.Item
            name={props.name}
            rules={[
               {
                  required: props.required,
                  message: `Please input your ${props.placeholder}`,
               },
               props.rules ?? {},
            ]}
            style={props.itemStyle}
            className={styles.item}>
            {props.textarea ? (
               <Input.TextArea
                  rows={3}
                  placeholder={props.placeholder}
                  prefix={props.prefix}
                  className={styles.input}
                  style={props.style}
               />
            ) : (
               <Input
                  type={props.type ? props.type : null}
                  placeholder={props.placeholder}
                  className={styles.input}
                  style={props.style}
                  disabled={props.disabled}
               />
            )}
         </Form.Item>
      </Tooltip>
   );
}

export default InputComp;
