import React, { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
//pages
import Landing from "./pages/landing";
import Main from "./pages/Main";
import Auth from "./pages/Auth";

import AppContext from "./contexts/AppContext";
import { setData, setDetails, signedIn } from "./contexts/actions/AppActions";
import { Services } from "./apis/Services/Services";
import useHandleErrors from "./hooks/useHandleErrors";
import useGetUserType from "./hooks/useGetUserType";
import Sharing from "./pages/sharing";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return null;
};

const RequireAuth = ({ children }) => {
  let location = useLocation();
  const { appState } = useContext(AppContext);

  if (!appState.userToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} />;
  }

  return children;
};

const RouterLinks = () => {
  const { appState, appDispatch } = useContext(AppContext);
  const [userType, setType] = useGetUserType();
  const [setError] = useHandleErrors();

  useEffect(() => {
    let token = localStorage.getItem("scout_token");
    if (token) {
      appDispatch(signedIn({ access: token }));
    }
  }, []);

  useEffect(() => {
    let token = localStorage.getItem("scout_token");
    let type = localStorage.getItem("scout_userType");
    if (appState.userToken) {
      setType(type);
      appDispatch(signedIn({ access: token, user_type: type }));
    }
  }, [appState.userToken]);

  React.useEffect(async () => {
    if (appState.userToken && userType) {
      await Services.get(`${userType}/me/`, true)
        .then(async (res) => {
          await localStorage.setItem("userData", JSON.stringify(res));
          appDispatch(setData(res));
        })
        .catch(setError);
      await Services.get(`users/me/`, true)
        .then(async (res) => {
          appDispatch(setDetails(res));
        })
        .catch(setError);
    }
  }, [userType]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/post/:id/*" element={<Sharing />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route
          path="/app/*"
          element={
            <RequireAuth>
              <Main />
            </RequireAuth>
          }
        />
        {/* 404 Route keep at bottom of routes */}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterLinks;
