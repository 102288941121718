import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Services } from "../../../../apis/Services/Services";
import Header from "../../../../components/header/Header";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import AppContext from "../../../../contexts/AppContext";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import useIsAuthenticatedUser from "../../../../hooks/useIsAuthenticatedUser";
import OverviewTab from "./OverviewTab";
import VideoTab from "./VideoTab";

export default function Profile() {
   const { appState } = React.useContext(AppContext);
   const [activeTab, setActiveTab] = React.useState(0);
   const [data, setData] = React.useState({});

   const isAuthenticatedUser = useIsAuthenticatedUser(data?.id);
   const [setError] = useHandleErrors();
   const location = useLocation();
   const params = useParams();

   useEffect(() => {
      if (
         location.search.includes("me") ||
         params.id === String(appState.data?.id)
      )
         setData(appState.data);
      if (params.id !== String(appState.data?.id)) getProfileData(params.id);
   }, []);

   const getProfileData = (id) => {
      Services.get(`playerapp/players/${id}/`, true)
         .then((res) => {
            setData(res);
         })
         .catch(setError);
   };

   const handleChange = (event, newValue) => {
      setActiveTab(newValue);
   };

   return (
      <div>
         <Header
            text={`${isAuthenticatedUser ? "My Profile" : "Academy"} `}
            popTo={isAuthenticatedUser}
            type="Player"
         />

         <TabsComponent
            tabs={["Overview", "Videos"]}
            value={activeTab}
            handleChange={handleChange}
         />

         <OverviewTab activeTab={activeTab} data={data} />

         <VideoTab activeTab={activeTab} data={data} />
      </div>
   );
}
