import React, { useEffect, useState } from "react";
import Moment from "moment";
import { Calendar } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "../calendar/calendar.css";
import { Services } from "../../apis/Services/Services";
import useHandleErrors from "../../hooks/useHandleErrors";

function CalendarComp(props) {
   const [selectedDay, setSelectedDay] = useState(null);
   const [events, setEvents] = useState([]);

   const [setError] = useHandleErrors();

   useEffect(() => {
      getEvents();
   }, []);

   const changeDate = (date) => {
      setSelectedDay(date);
      props.setSelectedDay(date);
   };

   const getEvents = () => {
      Services.get("event-points/day_map/?page_size=365", true)
         .then(async (res) => {
            let data = res.map((date) => {
               return {
                  year: Number(Moment(date.date, "yyyy-MM-dd").format("yyyy")),
                  month: Number(Moment(date.date, "yyyy-MM-dd").format("MM")),
                  day: Number(Moment(date.date, "yyyy-MM-DD").format("DD")),
                  className: "event",
               };
            });
            setEvents(data);
         })
         .catch(setError);
   };

   return (
      <Calendar
         value={selectedDay}
         onChange={changeDate}
         colorPrimary="#2C542A"
         customDaysClassName={events}
      />
   );
}

export default CalendarComp;
