import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { IconButton, useMediaQuery } from "@mui/material";
import { ProSidebar, Menu, MenuItem, SidebarFooter } from "react-pro-sidebar";
import { FaBars } from "react-icons/fa";
import "./sidebar.css";
import Icons from "../../constants/Icons";
import wadidegla from "../../assets/imgs/Wadi_Degla_Logo.png";
import footerLogo from "../../assets/imgs/FooterLogo.png";
import AppContext from "../../contexts/AppContext";
import BarMenu from "../IconsButtons/BarMenu";

const Links = [
   {
      icon: Icons.Home,
      label: "Home",
      text: "home",
      link: "/",
   },
   {
      icon: Icons.User,
      label: "Profile",
      text: "profile",
      link: "/profile",
   },
   {
      icon: Icons.Calendar,
      label: "Schedule",
      text: "schedule",
      link: "/schedule",
   },
   {
      icon: Icons.Academy,
      label: "Academy",
      text: "academy",
      link: "/academy",
   },
   {
      icon: Icons.More,
      label: "More",
      text: "more",
      link: "/more",
   },
];

const NavLink = ({ Icon, label, isActive, linkTo, onClick }) => {
   return (
      <div className="position-relative">
         <Link to={linkTo} className={isActive ? "active_link" : ""}>
            <MenuItem
               icon={
                  <Icon
                     style={{
                        width: 20,
                        height: 20,
                        fill: isActive ? "#fff" : "#959595",
                        stroke: isActive ? "#fff" : "#959595",
                     }}
                  />
               }
               onClick={onClick}
               style={{
                  backgroundColor: isActive ? "#ED6623" : "#fff",
                  color: isActive ? "#FFFFFF" : "#959595",
               }}>
               {label}
            </MenuItem>
         </Link>
      </div>
   );
};

function Sidebar({ setCollapse }) {
   const { appState } = React.useContext(AppContext);
   const matches = useMediaQuery("(min-width:600px)");

   const [checked, setChecked] = useState(false);

   const [active, setActive] = useState("home");
   const [show, setShow] = useState(false);

   useEffect(() => {
      setCollapse(checked);
   }, [checked]);

   const toggleShow = (item) => {
      setActive(item);
      setShow(!show);
   };

   return (
      <>
         {/* collapse */}
         {!matches && (
            <IconButton
               aria-label="close"
               onClick={toggleShow}
               sx={{
                  position: "absolute",
                  width: 50,
                  right: 10,
                  top: 0,
               }}>
               <BarMenu active={show} />
            </IconButton>
         )}
         <Col
            xs={2}
            lg={checked ? 1 : 2}
            className="p-0"
            style={{
               position: !matches ? "absolute" : "relative",
               width: !matches && 250,
               zIndex: show ? 1200 : 10,
               transform: !matches && (show ? "scale(1)" : "scale(.9)"),
               opacity: show ? 1 : matches ? 1 : 0,
               visibility: show ? "visible" : matches ? "visible" : "hidden",
               transitiondelay: show ? "0s" : "0s,0s,.25s",
               height: !matches && "100%",
               transition:
                  "transform .25s cubic-bezier(.5,0,.5,1),opacity .25s linear,visibility 0s linear",
            }}>
            <ProSidebar
               collapsed={checked}
               style={{
                  borderRadius: 10,
               }}
               collapsedWidth="70px">
               <div
                  style={{
                     display: "flex",
                     alignContent: "center",
                     flexDirection: "column",
                  }}>
                  <img
                     src={wadidegla}
                     style={{
                        width: checked ? 0 : 60,
                        height: checked ? 0 : 75,
                        alignSelf: "center",
                        marginBlock: 20,
                     }}
                     alt={appState.data?.username}
                  />
                  <div
                     className="AcademyName"
                     style={{
                        fontSize: 16,
                     }}>
                     {appState.data?.username}
                  </div>

                  <Menu style={{ alignSelf: checked ? "" : "center" }}>
                     {React.Children.toArray(
                        Links.map((item) =>
                           item.label === "Profile" &&
                           appState.userType === "adminuser" ? null : (
                              <NavLink
                                 Icon={item.icon}
                                 onClick={() => toggleShow(item.text)}
                                 label={item.label}
                                 isActive={active === item.text}
                                 linkTo={`/app${item.link}`}
                                 checked={checked}
                              />
                           )
                        )
                     )}
                  </Menu>
               </div>

               <SidebarFooter
                  style={{ display: "flex", justifyContent: "center" }}>
                  <img
                     src={footerLogo}
                     style={{
                        width: checked ? 0 : "80%",
                        height: checked ? 0 : 102,
                        objectFit: "contain",
                        marginTop: 20,
                     }}
                     alt="brand logo"
                  />
               </SidebarFooter>
            </ProSidebar>
         </Col>
      </>
   );
}

export default Sidebar;
