import React from "react";

export default function useGetUserType() {
   const [uType, setUType] = React.useState(null);
   const [userType, setUserType] = React.useState(null);

   React.useEffect(() => {
      if (uType) {
         if (uType === "playeruser") {
            setUserType("playerapp");
         } else if (uType === "parentuser") {
            setUserType("parentapp");
         } else if (uType === "coachuser") {
            setUserType("coachapp");
         } else if (uType === "adminuser") {
            setUserType("adminapp");
         }
      }
   }, [uType]);

   const setType = (data) => setUType(data);

   return [userType, setType];
}
