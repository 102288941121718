import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./PlayerAttributes.module.css";

export default function PlayerAttributes({ label, data }) {
   return (
      <div className={styles.content}>
         <div className={styles.header}>{label}</div>
         <div className="py-3">
            {React.Children.toArray(
               data?.map((item) => (
                  <Row className="m-0">
                     <Col xs={10} className={styles.state_name}>{item.name}</Col>
                     <Col xs={2}
                        className={`${styles.state_value} ${
                           item.value > 80 ? styles.state_increase : ""
                        }`}>
                        {item.value}
                     </Col>
                  </Row>
               ))
            )}
         </div>
      </div>
   );
}
