import React, { useEffect } from "react";
import {
   Dialog,
   DialogContent,
   DialogActions,
   IconButton,
   styled,
} from "@mui/material";
import { GrClose } from "react-icons/gr";

const BootstrapPopup = styled(Dialog)(({ theme }) => ({
   "& .MuiPaper-root": {
      borderRadius: 10,
   },
   "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
   },
   "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
   },
}));

const PopupClose = (props) => {
   const { onClose } = props;

   return (
      <>
         {onClose ? (
            <IconButton
               aria-label="close"
               onClick={onClose}
               sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  backgroundColor: "white",
               }}>
               <GrClose
                  style={{
                     fontSize: 18,
                  }}
               />
            </IconButton>
         ) : null}
      </>
   );
};

export default function Popup({ isOpened,setIsOpened, children, actions }) {
   const [open, setOpen] = React.useState(false);

   useEffect(() => {
      isOpened && setOpen(isOpened);
   }, [isOpened]);

   const handleClose = () => {
      setOpen(false);
      setIsOpened(false)
   };

   return (
      <BootstrapPopup
         onClose={handleClose}
         aria-labelledby="pop-up"
         maxWidth={"lg"}
         open={open}>
         <PopupClose onClose={handleClose} />
         <DialogContent>{children}</DialogContent>
         <DialogActions>{actions}</DialogActions>
      </BootstrapPopup>
   );
}
