import React, { Fragment } from "react";
import "../index.css";
import {
  Grid,
  Box,
  // Container,
  Typography,
  // TextField,
  // Button,
} from "@mui/material";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import StoreButtons from "./StoreButtons/StoreButtons";
import Images from "../constants/images";
import { Container } from "react-bootstrap";

export default function Footer() {
  return (
    <Fragment>
      <Box
        sx={{
          // my: 2,
          backgroundColor: "#5a5a5a",
          color: "white",
          "& Button": {
            color: "white",
            textAlign: { xs: "center", md: "left" },
          },
          textAlign: { xs: "none", md: "left" },
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              py: 3,
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Grid item md={4} xs={12} sx={{ mt: 5, mx: "auto" }}>
              <img src={Images.whiteBrandLogo} alt="scoutX logo" />
              <Typography
                variant={"p"}
                sx={{
                  display: "block",
                  my: 1,
                }}
                className="fs-17"
              >
                Download the app
              </Typography>
              <StoreButtons />
            </Grid>
            {/* <Grid item md={3} sx={{ mt: 3, mx: "auto" }}>
              <Button
                sx={{
                  display: "block",
                  "&:hover": {
                    color: "#ed6623",
                  },
                }}
                className="fs-17"
              >
                Who we are
              </Button>
              <Button
                sx={{
                  display: "block",
                  "&:hover": {
                    color: "#ed6623",
                  },
                }}
                className="fs-17"
              >
                Our partners
              </Button>
              <Button
                sx={{
                  ml: { xs: -0.5, md: 0 },
                  display: "block",
                  "&:hover": {
                    color: "#ed6623",
                  },
                }}
                className="fs-17"
              >
                FAQs
              </Button>

              <Button
                sx={{
                  display: "block",
                  "&:hover": {
                    color: "#ed6623",
                  },
                }}
                className="fs-17"
              >
                Packages
              </Button>
            </Grid> */}
            <Grid item md={5} sx={{ mt: 3 }} style={{
              display:'flex',
              alignItems:'center'
            }}>
              {/* <Typography
                variant={"p"}
                className="fs-17"
                sx={{ display: "block" }}
              >
                Contact us
              </Typography> */}

              {/* <TextField
                label="Email"
                type="email"
                sx={{
                  backgroundColor: "white",
                  borderRadius: 2,
                  width: "75%",
                  my: 1,
                  ml: { xs: 5, md: 0 },
                  display: "block",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#ed6623",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ":
                    {
                      borderColor: "#ed6623",
                    },
                }}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{
                  display: "block",
                  backgroundColor: "#ed6623",
                  borderRadius: 2,
                  width: "75%",
                  textAlign: "right",
                  ml: { xs: 5, md: 0 },
                  mb: 1,
                  "&:hover,&:focus": {
                    color: "#ed6623",
                    backgroundColor: "white",
                  },
                }}
              >
                Submit
              </Button> */}
              <Typography
                variant={"p"}
                className="fs-17"
                sx={{
                  display: { xs: "block", md: "inline" },
                }}
              >
                Follow us
              </Typography>
              <BsFacebook
                style={{
                  color: "white",
                  fontSize: 24,
                  marginInline: 15,
                }}
              />
              <BsTwitter
                style={{
                  color: "white",
                  fontSize: 24,
                  marginInline: 15,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
}
