import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Checkbox } from "antd";
import { signedIn } from "../../contexts/actions/AppActions";
import useHandleErrors from "../../hooks/useHandleErrors";
import { Services } from "../../apis/Services/Services";
import AppContext from "../../contexts/AppContext";
import InputComp from "../../components/inputs/InputComp";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";
import styles from "../../styles/auth.module.css";

const Login = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [setError] = useHandleErrors();
   const navigate = useNavigate();
   const { appDispatch } = useContext(AppContext);

   const onFinish = async (values) => {
      setIsLoading(true);
      await Services.set(values, "login/")
         .then((res) => {
            console.log(res);
            localStorage.setItem("scout_token", res.access);
            localStorage.setItem("scout_userType", res.user_type);
            appDispatch(signedIn(res));
            navigate("/app/", { replace: true });
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };

   return (
      <div>
         <div className={styles.header}>
            <div className={styles.authTitle}>Sign In</div>
            <div className={styles.account}>
               <div className={styles.noAcc}>Don’t have an account?</div>
               <Link to="signup">
                  <div className={styles.sign}>Sign Up</div>
               </Link>
            </div>
         </div>
         <Form
            initialValues={{
               remember: true,
            }}
            onFinish={onFinish}
            className={styles.form}>
            <InputComp
               required
               name="email"
               placeholder="Username"
               rules={{
                  type: "email",
                  message: "The input is not valid E-mail!",
               }}
            />

            <InputComp
               required
               type="password"
               name="password"
               placeholder="Password"
            />

            <div className={styles.remember}>
               <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className={styles.checkbox}>
                  <Checkbox>Remember me</Checkbox>
               </Form.Item>
               <Link to="password-recover">
                  <div className={styles.forgotPass}>Forgot password</div>
               </Link>
            </div>

            <ButtonComponent text="SIGN IN" isLoading={isLoading} />
         </Form>
      </div>
   );
};

export default Login;
