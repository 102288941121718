import React from "react";
import Header from "../../../components/header/Header";
import Text from "../../../components/Text";
import AppContext from "../../../contexts/AppContext";

const Tab = ({ click, text }) => (
   <div
      style={{
         borderBottom: "1px solid #AFAFAF",
         paddingBlock: 10,
      }}>
      <Text bold secondary fSize={20}>
         {text}
      </Text>
   </div>
);

export default function More() {
   const { appState } = React.useContext(AppContext);

   return (
      <div>
         <Header text="More" />
         <div className="py-4">
            {appState.userType === "adminuser" && (
               <>
                  <Tab text="Edit academy info" />
                  <Tab text="Edit academy" />
               </>
            )}
            <Tab text="Announcements" />
            <Tab text="Messages" />
            {appState.userType === "playeruser" && (
               <Tab text="Player unique code" />
            )}
            <Tab text="Terms and conditions" />
            <Tab text="Privacy policy" />
            <Tab text="FAQs" />
            <Tab text="Change password" />
            <Tab text="Contact us" />
            <Tab text="Log out" />
         </div>
      </div>
   );
}
