import WhiteBrandLogo from "../assets/landingPage/Mask Group 15@2x.png";
import ColoredBrandLogo from "../assets/imgs/logo1.png";

import AppStore from "../assets/imgs/apple.png";
import GoogleStore from "../assets/imgs/google.png";

const Images = {
   appStore: AppStore,
   googleStore: GoogleStore,
   whiteBrandLogo: WhiteBrandLogo,
   coloredBrandLogo:ColoredBrandLogo
};

export default Images;
