import React from "react";
import { Form, Select, Tooltip } from "antd";
import styles from "./inputs.module.css";
const { Option } = Select;

function SelectComponent(props) {
   return (
      <Tooltip
         title={props.placeholder}
         placement="bottom">
         <Form.Item
            name={props.name}
            style={props.itemStyle}
            rules={[
               {
                  required: props.required,
                  message: `Please input your ${
                     props.dummy ?? props.placeholder
                  }`,
               },
            ]}>
            <Select
               mode={props.mode}
               placeholder={props.placeholder}
               className={styles.input}
               style={props.style}>
               {!props.mode && (
                  <Option value="" disabled>
                     {props.placeholder}
                  </Option>
               )}
               {React.Children.toArray(
                  props.data.map((c) => <Option value={c.id}>{c.name}</Option>)
               )}
            </Select>
         </Form.Item>
      </Tooltip>
   );
}

export default SelectComponent;
