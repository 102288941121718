import React from "react";
import { Row, Col } from "react-bootstrap";
import Moment from "moment";
import { Services } from "../../../apis/Services/Services";
import CalendarComp from "../../../components/calendar/CalendarComp";
import ScheduleCard from "../../../components/cards/ScheduleCard/ScheduleCard";
import Header from "../../../components/header/Header";
import useHandleErrors from "../../../hooks/useHandleErrors";
import EmptyComponent from "../../../components/EmptyComponent/EmptyComponent";
import AppContext from "../../../contexts/AppContext";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";

export default function Schedule() {
   const { appState } = React.useContext(AppContext);

   const [events, setEvents] = React.useState([]);
   const [loading, setLoading] = React.useState(false);
   const [date, setDate] = React.useState(new Date());
   const [setError] = useHandleErrors();

   React.useEffect(() => {
      getEvents(date);
   }, [date]);

   const getEvents = (date) => {
      setLoading(true);
      let formatedDate = Moment(date).format("yyyy-MM-DD");
      Services.get(`event-points/?date=${formatedDate}&page_size=80`, true)
         .then((res) => {
            setEvents(res.results);
         })
         .catch(setError)
         .finally(() => setLoading(false));
   };

   return (
      <div>
         <Header text="Schedule" />
         <Row>
            <Col lg={4}>
               <CalendarComp
                  setSelectedDay={(e) =>
                     setDate(new Date(`${e.year}-${e.month}-${e.day}`))
                  }
               />
            </Col>
            <Col lg={2}></Col>
            <Col lg={6}>
               {appState.userType !== "playeruser" &&
                  appState.userType !== "parentuser" && (
                     <ButtonComponent
                        text="ADD EVENT"
                        btnStyle={{
                           marginTop: 5,
                        }}
                     />
                  )}

               {events.length !== 0 ? (
                  <>
                     {React.Children.toArray(
                        events.map((item) => <ScheduleCard item={item} />)
                     )}
                  </>
               ) : (
                  <EmptyComponent
                     text="No schedule in that day"
                     loading={loading}
                  />
               )}
            </Col>
         </Row>
      </div>
   );
}
