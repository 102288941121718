import React, { useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Services } from "../../../apis/Services/Services";
import NewsfeedCard from "../../../components/cards/newsfeed/NewsfeedCard";
import Post from "../../../components/cards/post/Post";
import Header from "../../../components/header/Header";
import SearchInput from "../../../components/inputs/SearchInput";
import LoadMore from "../../../components/loadmore/LoadMore";
import useHandleErrors from "../../../hooks/useHandleErrors";
import Icons from "../../../constants/Icons";
import EmptyComponent from "../../../components/EmptyComponent/EmptyComponent";

export default function NewsFeed() {
   const [data, setData] = React.useState([]);
   const [moreData, setMoreData] = React.useState([]);

   const [searchText, setSearchText] = React.useState("");
   const [loading, setLoading] = React.useState(false);

   const [setError] = useHandleErrors();
   const navigate = useNavigate();

   useEffect(() => {
      getData(searchText);
   }, [searchText]);

   const getData = async (search = searchText) => {
      setLoading(true);
      await Services.get(`posts/?search=${search}&ordering=-id`, true)
         .then(async (res) => {
            setMoreData(res);
            setData(res.results);
         })
         .catch(setError)
         .finally(() => setLoading(false));
   };

   const getMoreData = (res) => {
      setMoreData(res);
      setData([...res.results]);
   };

   const navigationTo = (link) => {
      navigate(link);
   };

   return (
      <div>
         <Header text="Newsfeed" />
         <div>
            <SearchInput onSearch={setSearchText} />
            <IconButton
               aria-label="add-post"
               onClick={() => navigationTo("/app/add/post")}
               sx={{
                  backgroundColor: "#F4F5F6",
                  borderRadius: 1,
                  m: 1,
               }}>
               <BsPencilSquare
                  style={{
                     fontSize: 24,
                     color: "#ed6623",
                  }}
               />
            </IconButton>
            <IconButton
               aria-label="add-video"
               onClick={() => navigationTo("/app/add/video")}
               sx={{
                  backgroundColor: "#F4F5F6",
                  borderRadius: 1,
                  m: 1,
               }}>
               <img
                  src={Icons.Video}
                  alt="add post video"
                  width={30}
                  height={25}
               />
            </IconButton>
         </div>
         {data.length === 0 ? (
            <EmptyComponent text="No Posts found" loading={loading} />
         ) : (
            <>
               {React.Children.toArray(
                  data.map((item) =>
                     item.type === "announcement" ||
                     item.type === "event_point" ? (
                        <NewsfeedCard item={item} />
                     ) : (
                        <Post item={item} />
                     )
                  )
               )}
               <LoadMore
                  next={moreData.next}
                  prev={moreData.previous}
                  newData={getMoreData}
               />
            </>
         )}
      </div>
   );
}
