import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "../../../styles/auth.module.css";
import InputComp from "../../../components/inputs/InputComp";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { Services } from "../../../apis/Services/Services";

function NewPassword() {
   const [setError] = useHandleErrors();
   const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [code, setCode] = useState("");
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      let email = localStorage.getItem("email");
      setEmail(email);
      let code = localStorage.getItem("code");
      setCode(code);
   }, []);

   const onFinish = async (values) => {
      setIsLoading(true);
      values.email = email;
      values.code = code;
      await Services.set(values, "/user_password/reset_password/")
         .then((res) => {
            navigate("/auth/password-success");
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };
   return (
      <div>
         <div className={styles.header}>
            <div className={styles.authCodeTitle}>New Password</div>
         </div>

         <Form onFinish={onFinish} className={styles.form}>
            <div className={styles.center}>
               <InputComp
                  required={true}
                  name="password"
                  type="password"
                  placeholder="New Password"
               />
               <InputComp
                  required={true}
                  type="password"
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  rules={({ getFieldValue }) => ({
                     validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                           return Promise.resolve();
                        }
                        return Promise.reject(
                           new Error(
                              "The two passwords that you entered do not match!"
                           )
                        );
                     },
                  })}
               />
            </div>
            <ButtonComponent text="Confirm" isLoading={isLoading} />
         </Form>
      </div>
   );
}

export default NewPassword;
