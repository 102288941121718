import React from "react";
import styled from "styled-components";
import { Card, CardContent, CardActionArea } from "@mui/material";
import styles from "../cards.module.css";
import Text from "../../Text";

const CardBG = styled(Card)`
   background-image: ${(props) =>
      `linear-gradient(180deg, #00000000 0%, #000000 100%), url('${props.bgimage}') `};
`;

function EventCard({ Picture, Title, Event, Date, onClick }) {
   return (
      <CardBG
         bgimage={Picture}
         className={styles.eventCard}
         sx={{
            maxWidth: 468,
            maxHeight: 332,
            minHeight: 230,
         }}>
         <CardActionArea onClick={onClick} className={styles.action}>
            <CardContent sx={{ width: 350 }}>
               <Text textClass={styles.label}>{Title}</Text>
               <Text textClass={styles.text} bold>
                  {Event}
               </Text>
               <Text textClass={styles.label}>Date</Text>
               <Text textClass={styles.text} fSize={16} bold>
                  {Date}
               </Text>
            </CardContent>
         </CardActionArea>
      </CardBG>
   );
}

export default EventCard;
