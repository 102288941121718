import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./Playground.module.css";
import { BsFillCircleFill } from "react-icons/bs";

const PositionDot = ({ position, hisPosition }) => {
   return (
      <BsFillCircleFill
         style={{
            color: hisPosition === position ? "#ed6623" : "#fff",
         }}
      />
   );
};

const Left = ["LW", "LM", "LB"];
const Center = ["ST", "CAM", "CM", "CDM", "CB", "GK"];
const Right = ["RW", "RM", "RB,RWB"];

const Playground = ({position}) => {
   return (
      <div className={styles.bg}>
         <Row
            style={{
               height: "100%",
               padding: 24,
            }}>
            <Col
               style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
               }}>
               {React.Children.toArray(
                  Left.map((item) => (
                     <PositionDot position={item} hisPosition={position} />
                  ))
               )}
            </Col>

            <Col
               style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  alignItems: "center",
               }}>
               {React.Children.toArray(
                  Center.map((item) => (
                     <PositionDot position={item} hisPosition={position} />
                  ))
               )}
            </Col>

            <Col
               style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  flexDirection: "column",
               }}>
               {React.Children.toArray(
                  Right.map((item) => (
                     <PositionDot position={item} hisPosition={position} />
                  ))
               )}
            </Col>
         </Row>
      </div>
      // <ImageBackground style={styles.GroundBackground} source={require("../../../assets/Imgs/playGround.png")}>
      //   <View style={styles.rowAllPoints}>
      //     <View style={styles.col_left}>
      //       <Octicons name="primitive-dot" value="LW" size={24} color={props.position == "LW" ? Colors.primary : Colors.white} style={styles.pointLW} />
      //       <Octicons name="primitive-dot" value="LM" size={24} color={props.position == "LM" ? Colors.primary : Colors.white} style={styles.pointLM} />
      //       <Octicons name="primitive-dot" value="LB" size={24} color={props.position == "LB" || props.position == "LWB" ? Colors.primary : Colors.white} style={styles.pointLB} />
      //     </View>

      //     {/* second column */}
      //     <View style={styles.col_center}>
      //       <Octicons name="primitive-dot" value="ST" size={24} color={props.position == "ST" ? Colors.primary : Colors.white} style={styles.pointST} />
      //       <Octicons name="primitive-dot" value="CAM" size={24} color={props.position == "CAM" ? Colors.primary : Colors.white} style={styles.pointCAM} />
      //       <Octicons name="primitive-dot" value="CM" size={24} color={props.position == "CM" ? Colors.primary : Colors.white} style={styles.pointCM} />
      //       <Octicons name="primitive-dot" value="CDM" size={24} color={props.position == "CDM" ? Colors.primary : Colors.white} style={styles.pointCDM} />
      //       <Octicons name="primitive-dot" value="CB" size={24} color={props.position == "CB" ? Colors.primary : Colors.white} style={styles.pointCB} />
      //       <Octicons name="primitive-dot" value="GK" size={24} color={props.position == "GK" ? Colors.primary : Colors.white} style={styles.pointGK} />
      //     </View>

      //     {/* third column */}
      //     <View style={styles.col_left}>
      //       <Octicons name="primitive-dot" value="RW" size={24} color={props.position == "RW" ? Colors.primary : Colors.white} style={styles.pointLW} />
      //       <Octicons name="primitive-dot" value="RM" size={24} color={props.position == "RM" ? Colors.primary : Colors.white} style={styles.pointLM} />
      //       <Octicons name="primitive-dot" value="RM,RWM" size={24} color={props.position == "RB" || props.position == "RWB" ? Colors.primary : Colors.white} style={styles.pointLB} />
      //     </View>
      //   </View>
      // </ImageBackground>
   );
};

// define your styles
// const styles = StyleSheet.create({
//   GroundBackground: {
//     resizeMode: "cover",
//     height: 250,
//     width: 206,
//     paddingHorizontal: 16,
//   },
//   rowAllPoints: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//   },
//   col_left: {
//     flexDirection: "column",
//     top: vh(4),
//     paddingHorizontal: 10,
//   },
//   col_center: {
//     flexDirection: "column",
//     top: vh(2.5),
//     // paddingLeft: vw(10)
//   },
//   pointLM: {
//     top: vh(6),
//   },
//   pointLB: {
//     top: vh(13),
//   },
//   pointCAM: {
//     top: vh(1),
//   },
//   pointCM: {
//     top: vh(3),
//   },
//   pointCDM: {
//     top: vh(5),
//   },
//   pointCB: {
//     top: vh(8),
//   },
//   pointGK: {
//     top: vh(9),
//   },
// });

//make this component available to the app
export default Playground;
