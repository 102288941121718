import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Services } from "../../../../apis/Services/Services";
import Header from "../../../../components/header/Header";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import useIsAuthenticatedUser from "../../../../hooks/useIsAuthenticatedUser";
import AnalyticCard from "../../../../components/cards/AnalyticCard/AnalyticCard";
import TabContent from "../../../../components/tabs/TabContent";
import Chips from "../../../../components/chips/Chips";
import PlayerInfo from "../../../../components/cards/playerinfo/PlayerInfo";
import PlayerDetails from "../../../../components/playerDetails/PlayerDetails";
import AppContext from "../../../../contexts/AppContext";
import Icons from "../../../../constants/Icons";
import Text from "../../../../components/Text";
import { Box } from "@mui/material";
import AcademyCard from "../../../../components/cards/Academy/AcademyCard";

const years = [
   {
      id: "",
      name: "2019",
   },
   {
      id: 1,
      name: "2020",
   },
   {
      id: 2,
      name: "2021",
   },
];
const achievements = [
   {
      id: "",
      name: "Tactics",
   },
   {
      id: 1,
      name: "Communication",
   },
   {
      id: 2,
      name: "Leadership",
   },
   {
      id: 3,
      name: "Technical Development",
   },
   {
      id: 4,
      name: "Ability to motivate",
   },
   {
      id: 5,
      name: "Fitness Guidance",
   },
   {
      id: 6,
      name: "Technical Development",
   },
];
export default function CoachProfile() {
   const { appState } = React.useContext(AppContext);
   const [activeTab, setActiveTab] = React.useState(0);
   const [data, setData] = React.useState({});

   const isAuthenticatedUser = useIsAuthenticatedUser(data?.id);
   const [setError] = useHandleErrors();
   const location = useLocation();
   const params = useParams();

   useEffect(() => {
      getProfileData(params.id);
   }, []);

   const getProfileData = (id) => {
      Services.get(`coachapp/coaches/${id}/`, true)
         .then((res) => {
            setData(res);
         })
         .catch(setError);
   };

   const handleChange = (event, newValue) => {
      setActiveTab(newValue);
   };

   return (
      <div>
         <Header
            text={`${isAuthenticatedUser ? "My Profile" : "Academy"} `}
            popTo={isAuthenticatedUser}
            type="Player"
         />

         <TabsComponent
            tabs={["Overview"]}
            value={activeTab}
            handleChange={handleChange}
         />

         <TabContent value={activeTab} index={0}>
            <Row>
               <Col lg={6}>
                  <Box sx={{ mb: 2 }}>
                     <Row>
                        <Col lg={6}>
                           <img
                              src={data?.picture}
                              style={{
                                 width: "100%",
                                 borderRadius: 5,
                                 paddingInline: 16,
                                 marginBottom: 10,
                              }}
                           />
                        </Col>
                        <Col lg={6}>
                           <Text bold secondary marginBottom>
                              {data?.username}
                           </Text>
                           <PlayerDetails
                              label="Academy"
                              value={data?.academy?.name}
                           />
                           <PlayerDetails
                              label="ROLE MODEL"
                              value={data?.role_model}
                           />
                        </Col>
                     </Row>
                  </Box>
                  <Box>
                     <Text bold fSize={20} secondary marginBottom>
                        Bio
                     </Text>
                     <Text fSize={18} marginBottom>
                        {data?.bio}
                     </Text>
                  </Box>
                  <Box>
                     <Text bold fSize={20} secondary marginBottom>
                        Managed teams
                     </Text>
                     {React.Children.toArray(
                        data?.team_set?.map((item) => (
                           <AcademyCard item={item} type="Teams" />
                        ))
                     )}
                  </Box>
               </Col>
               <Col
                  lg={6}
                  style={{
                     borderLeft: "1px solid #e0e0e0",
                  }}>
                  <Text bold fSize={20} secondary marginBottom>
                     Achievements & Endorsements
                  </Text>
                  <Box>
                     {React.Children.toArray(
                        achievements.map((item) => (
                           <Text fSize={21} marginBottom>
                              {item.name} (0)
                           </Text>
                        ))
                     )}
                  </Box>
               </Col>
            </Row>

            <Text bold fSize={20} secondary>
               Player Stats
            </Text>

            <Row className="align-items-center">
               <Col lg={2}>
                  <Text bold fSize={20} primary>
                     Select team
                  </Text>
               </Col>
               <Col>
                  <Chips
                     data={data?.team_set??[]}
                     handleActive={(e) => console.log(e)}
                  />
               </Col>
            </Row>
            <Row className="align-items-center">
               <Col lg={2}>
                  <Text bold fSize={20} primary>
                     Select year
                  </Text>
               </Col>
               <Col>
                  <Chips data={years} handleActive={(e) => console.log(e)} />
               </Col>
            </Row>
            <Row>
               <AnalyticCard label="MATCHES PLAYED" count={73} />
               <AnalyticCard label="GOALS SCORED" count={44} />
               <AnalyticCard label="ASSISTS MADE" count={20} />
            </Row>
         </TabContent>
      </div>
   );
}
