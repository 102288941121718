import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Box } from "@mui/material";
import AnalyticCard from "../../../../components/cards/AnalyticCard/AnalyticCard";
import TabContent from "../../../../components/tabs/TabContent";
import Chips from "../../../../components/chips/Chips";
import Playground from "../../../../components/playground/Playground";
import PlayerInfo from "../../../../components/cards/playerinfo/PlayerInfo";
import PlayerDetails from "../../../../components/playerDetails/PlayerDetails";
import AppContext from "../../../../contexts/AppContext";
import PlayerAttributes from "../../../../components/playerAttributes/PlayerAttributes";
import Icons from "../../../../constants/Icons";
import Text from "../../../../components/Text";
import LinkAcademy from "../../../../components/popups/LinkAcademy/LinkAcademy";
import useIsAuthenticatedUser from "../../../../hooks/useIsAuthenticatedUser";

const years = [
   {
      id: "",
      name: "2019",
   },
   {
      id: 1,
      name: "2020",
   },
   {
      id: 2,
      name: "2021",
   },
];

export default function OverviewTab({ data, activeTab }) {
   const { appState } = React.useContext(AppContext);
   const [isLinkedShow, setIsLinkedShow] = React.useState(false);
   const isAuthenticatedUser = useIsAuthenticatedUser(data?.id);

   return (
      <TabContent value={activeTab} index={0}>
         <Row className="align-items-center">
            <Col lg={2} className="d-flex justify-content-center">
               <PlayerInfo data={data} />
            </Col>
            <Col>
               {isAuthenticatedUser && (
                  <Link to="/app/profile/edit">
                     <div className="row align-items-center">
                        <img
                           src={Icons.Group}
                           alt="Edit profile"
                           style={{
                              width: 60,
                              height: 35,
                           }}
                        />
                        <Text
                           bold
                           secondary
                           textStyle={{
                              display: "inline",
                              textDecoration: "underline",
                              width: "fit-content",
                           }}>
                           Edit
                        </Text>
                     </div>
                  </Link>
               )}
               <Row>
                  <Col lg={3} xs={6}>
                     <PlayerDetails
                        label="ACADEMY"
                        value={
                           !isAuthenticatedUser ? (
                              data?.academy?.name
                           ) : appState.details.academies?.length !== 0 ? (
                              appState.details?.playeruser?.academy?.name
                           ) : (
                              <div onClick={() => setIsLinkedShow(true)}>
                                 <Text
                                    fSize={10}
                                    primary
                                    textStyle={{
                                       textDecoration: "underline",
                                       cursor: "pointer",
                                    }}>
                                    LINK TO ACADEMY
                                 </Text>
                              </div>
                           )
                        }
                     />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails label="AGE" value={data?.age} />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails label="TEAM" value={data?.team_obj?.name} />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails
                        label="Pref. foot"
                        value={
                           data?.preferred_foot === "right_foot"
                              ? "Right Foot"
                              : "Left Foot"
                        }
                     />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails
                        label="Height"
                        value={`${data?.height} Cm`}
                     />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails
                        label="Weight"
                        value={`${data?.weight} KG`}
                     />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails
                        label="Role Model"
                        value={data?.role_model}
                     />
                  </Col>

                  <Col lg={3} xs={6}>
                     <PlayerDetails
                        label="Dream team"
                        value={data?.dream_team}
                     />
                  </Col>
               </Row>
            </Col>
            <Col lg={3} className="d-flex justify-content-center">
               <Playground position={data?.primary_position} />
            </Col>
         </Row>

         <Box
            sx={{
               boxShadow: "0px 0px 36px #00000029",
               background: "#fff",
               borderRadius: 2,
               padding: 2,
               mt: 2,
               mb: 2,
            }}>
            <Row>
               {data?.primary_position === "GK" ? (
                  <Col lg={4}>
                     <PlayerAttributes
                        label="GoalKeeping"
                        data={[
                           {
                              name: "Aerial Reach",
                              value: "92",
                           },
                           {
                              name: "Communication",
                              value: "92",
                           },
                           {
                              name: "Handling",
                              value: "73",
                           },
                           {
                              name: "Kicking",
                              value: "73",
                           },
                           {
                              name: "One on Ones",
                              value: "73",
                           },
                           {
                              name: "Reflexes",
                              value: "73",
                           },
                           {
                              name: "Throwing",
                              value: "73",
                           },
                           {
                              name: "Punching",
                              value: "73",
                           },
                        ]}
                     />
                  </Col>
               ) : (
                  <>
                     <Col lg={4}>
                        <PlayerAttributes
                           label="Technical"
                           data={[
                              {
                                 name: "Creativity",
                                 value: "92",
                              },
                              {
                                 name: "Crossing",
                                 value: "92",
                              },
                              {
                                 name: "Dribbling",
                                 value: "73",
                              },
                              {
                                 name: "Finishing",
                                 value: "73",
                              },
                              {
                                 name: "First Touch",
                                 value: "73",
                              },
                              {
                                 name: "Heading",
                                 value: "73",
                              },
                              {
                                 name: "Marking",
                                 value: "73",
                              },
                              {
                                 name: "Tackling",
                                 value: "73",
                              },
                              {
                                 name: "Passing",
                                 value: "73",
                              },
                              {
                                 name: "Penalty Taking",
                                 value: "73",
                              },
                           ]}
                        />
                     </Col>

                     <Col lg={4}>
                        <PlayerAttributes
                           label="Mental"
                           data={[
                              {
                                 name: "Anticipation",
                                 value: "92",
                              },
                              {
                                 name: "Bravery",
                                 value: "92",
                              },
                              {
                                 name: "Decisions",
                                 value: "73",
                              },
                              {
                                 name: "Flair",
                                 value: "73",
                              },
                              {
                                 name: "Leadership",
                                 value: "73",
                              },
                              {
                                 name: "Off the ball",
                                 value: "73",
                              },
                              {
                                 name: "Positioning",
                                 value: "73",
                              },
                              {
                                 name: "Teamwork",
                                 value: "73",
                              },
                              {
                                 name: "Vision",
                                 value: "73",
                              },
                           ]}
                        />
                     </Col>

                     <Col lg={4}>
                        <PlayerAttributes
                           label="Physical"
                           data={[
                              {
                                 name: "Agility",
                                 value: "92",
                              },
                              {
                                 name: "Pace",
                                 value: "92",
                              },
                              {
                                 name: "Speed",
                                 value: "73",
                              },
                              {
                                 name: "Stamina",
                                 value: "73",
                              },
                              {
                                 name: "Strength",
                                 value: "73",
                              },
                           ]}
                        />
                     </Col>
                  </>
               )}
            </Row>
         </Box>
         <Text bold fSize={20} secondary>
            Player Stats
         </Text>
         <Row className="align-items-center">
            <Col lg={2}>
               <Text bold fSize={20} primary>
                  Select year
               </Text>
            </Col>
            <Col>
               <Chips data={years} handleActive={(e) => console.log(e)} />
            </Col>
         </Row>
         <Row>
            <AnalyticCard label="MATCHES PLAYED" count={73} />
            <AnalyticCard label="GOALS SCORED" count={44} />
            <AnalyticCard label="ASSISTS MADE" count={20} />
         </Row>

         {isLinkedShow && (
            <LinkAcademy
               isOpened={isLinkedShow}
               setIsOpened={setIsLinkedShow}
            />
         )}
      </TabContent>
   );
}
