import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Services } from "../apis/Services/Services";
import stylesPage from "../styles/landing.module.css";
import { Card, CardContent, CardMedia, CardActionArea } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import styles from "./cards.module.css";
import useHandleErrors from "../hooks/useHandleErrors";
import Text from "../components/Text";

function Sharing() {
  const [video, setVideo] = useState();
  const params = useParams();
  const [isLiked, setIsLiked] = React.useState(false);
  const [isOpened, setIsOpened] = React.useState(false);

  const [likesCount, setLikesCount] = React.useState();
  // const [commentsCount, setCommentsCount] = React.useState(0);
  const [setError] = useHandleErrors();

  useEffect(() => {
    getData();
  }, []);

  const sendLike = async () => {
    await Services.set({}, `posts/${params.id}/toggle_like/`, false).then(
      async (res) => {
        console.log(res);
        setLikesCount(likesCount + 1);
      }
    );
  };

  const toggleLike = () => {
    let x = JSON.parse(localStorage.getItem("shareLikes"));
    if (!x) {
      localStorage.setItem("shareLikes", JSON.stringify([video.id]));
      sendLike();
    } else if (!x.includes(video.id)) {
      x.push(video.id);
      localStorage.setItem("shareLikes", JSON.stringify(x));
      sendLike();
    }
    setIsLiked(true);
  };

  const incrementView = async () => {
    await Services.set({}, `posts/${params.id}/increment-views/`, false).then(
      async (res) => {
        console.log(res);
      }
    );
  };

  const getData = async () => {
    await Services.get(`posts/${params.id}/`, false)
      .then(async (res) => {
        setVideo(res);
        setLikesCount(res?.reactions_count);
        incrementView();
        let localStorageLikedPosts = JSON.parse(
          localStorage.getItem("shareLikes")
        );
        if (
          localStorageLikedPosts &&
          localStorageLikedPosts.includes(res?.id)
        ) {
          setIsLiked(true);
        }
      })
      .catch(async (err) => {
        console.log(err, "err");
      });
  };

  return (
    <>
      <div className={styles.page}>
        {video && (
          <Card
            className={styles.postCard}
            sx={{
              borderRadius: 2,
            }}
          >
            <CardActionArea>
              {video.picture ? (
                <CardMedia
                  component={"img"}
                  height="180"
                  src={
                    video.picture ??
                    "https://gskpro.com/etc/designs/zg-placeholders/video.png"
                  }
                  alt={video.title}
                />
              ) : (
                <CardMedia
                  component={video.video ? "video" : "img"}
                  controls
                  height={"600"}
                  src={
                    video.video + '#t=0.001' ??
                    "https://gskpro.com/etc/designs/zg-placeholders/video.png"
                  }
                  alt={video.title}
                />
              )}

              <CardContent className="pt-1">
                <div className={styles.actionsShare}>
                  <div className={styles.item} onClick={() => toggleLike()}>
                    {isLiked ? (
                      <AiFillLike
                        style={{
                          fontSize: 20,
                          color: "#ED6623",
                        }}
                      />
                    ) : (
                      <AiOutlineLike
                        style={{
                          fontSize: 20,
                          color: "#ED6623",
                        }}
                      />
                    )}
                  </div>
                </div>
                <Text primary bold fSize={16}>
                  {video.title}
                </Text>
                <Text fSize={14}>
                  <Text
                    bold
                    primary
                    textStyle={{
                      display: "inline",
                      paddingRight: 5,
                    }}
                  >
                    By
                  </Text>
                  {video.user_obj.username} ({video.user_obj.user_type})
                </Text>
                {/* tagged Player */}
                {video?.mentions_obj[0] && (
                  <Text fSize={14}>
                    <Text
                      bold
                      primary
                      textStyle={{
                        display: "inline",
                        paddingRight: 5,
                      }}
                    >
                      Tagged Player
                    </Text>
                    {video?.mentions_obj[0]?.username} (
                    {video?.mentions_obj[0]?.user_type})
                  </Text>
                )}
                <Row>
                  <Col>
                    <Text primary fSize={14}>
                      {likesCount} <span>LIKES</span>
                    </Text>
                  </Col>
                </Row>
                <div className={styles.tags}>
                  <span>TAGS:</span>{" "}
                  {video.evaluation_tags_arr.map((tag) => tag.name + " , ")}
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        )}
      </div>
    </>
  );
}

export default Sharing;
