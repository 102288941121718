import DomainUrl from "../Domains";

export const ENDPOINTS = {
   get: {
      url: (params) => `${DomainUrl}/${params}`,
      method: "GET",
   },
   set: {
      url: (params) => `${DomainUrl}/${params}`,
      method: "POST",
   },
   patch: {
      url: (params) => `${DomainUrl}/${params}`,
      method: "PATCH",
   },
};
