import { useReducer } from "react";
import AppContext from "./contexts/AppContext";
import { AppReducer } from "./contexts/reducers/AppReducer";
import RouterLinks from "./router";
import "./App.css";

function App() {
   const [appState, appDispatch] = useReducer(AppReducer, {
      data: {},
      details: {},
      isSignout: false,
      userToken: null,
      userType: null,
   });

   return (
      <AppContext.Provider value={{ appState, appDispatch }}>
         <RouterLinks />
      </AppContext.Provider>
   );
}

export default App;
