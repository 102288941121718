import React from "react";
import { Box } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import Popup from "../Popup";
import ButtonComponent from "../../buttonComponent/ButtonComponent";
import Text from "../../Text";
import { Services } from "../../../apis/Services/Services";
import AppContext from "../../../contexts/AppContext";
import useHandleErrors from "../../../hooks/useHandleErrors";

const urls = [
   {
      api: (id) => `teams/?page_size=50&academy=${id}`,
      label: "teams",
   },
   {
      api: () => `playerapp/player-positions-types/`,
      label: "positions",
   },
   {
      api: (id) => `academy-age-categories/?page_size=50&academy=${id}`,
      label: "ages",
   },
];

export default function AcademyFilters({ isOpened, setIsOpened }) {
   const [data, setData] = React.useState({});
   const { appState } = React.useContext(AppContext);
   const [setError] = useHandleErrors();

   React.useEffect(() => {
      isOpened && getData();
   }, [isOpened]);

   const getData = async () => {
      for (let i = 0; i < urls.length; i++) {
         const element = urls[i];
         Services.get(element.api(appState.details.academies[0].id), true)
            .then((res) => {
               setData((old) => {
                  return { ...old, [element.label]: res.results || res };
               });
            })
            .catch(setError);
      }
   };

   return (
      <Popup isOpened={isOpened} setIsOpened={setIsOpened}>
         <Box
            sx={{
               minWidth: 300,
               width: 500,
            }}>
            <Text fSize={24} bold primary marginBottom>
               Filters
            </Text>

            <Row>
               <Col lg={4}>
                  <Text bold secondary marginBottom>
                     Teams
                  </Text>
               </Col>
               <Col lg={4}>
                  <Text bold secondary marginBottom>
                     Postions
                  </Text>
               </Col>
               <Col lg={4}>
                  <Text bold secondary marginBottom>
                     Age
                  </Text>
               </Col>
            </Row>

            <ButtonComponent
               text="APPLY"
               btnStyle={{
                  fontSize: 16,
                  height: 40,
               }}
            />
         </Box>
      </Popup>
   );
}
