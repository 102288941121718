import React, { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-grid-carousel";
import { useMediaQuery } from "@mui/material";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import img from "../assets/imgs/Image.png";
import mission from "../assets/imgs/mission.png";
import Image from "../assets/imgs/Group 1692.png";
import PlayerCard from "../assets/landingPage/playerCard.png";

import carouselred from "../assets/imgs/CarouselRed.png";
import carouselyellow from "../assets/imgs/CarouselYellow.png";
import Stars from "../assets/imgs/Stars.png";
import char from "../assets/imgs/Charlotte.png";
import DA from "../assets/imgs/DA.png";
import GA from "../assets/imgs/GA.png";
import compete from "../assets/imgs/compete.png";
import Sponsers from "../assets/imgs/Sponsers.png";
import styles from "../styles/landing.module.css";
import postImg from "../assets/imgs/post.png";

import Plans from "../components/Plans";
import Team from "../components/Team";
import Footer from "../components/Footer";
import Images from "../constants/images";
import StoreButtons from "../components/StoreButtons/StoreButtons";
import NavbarComp from "../components/navBar/NavbarComp";
import EventCard from "../components/cards/EventCard/EventCard";
import PopUp from "../components/popUp";
import Text from "../components/Text";
import Cards from "../constants/LandingCards";

function Landing() {
   const [isCardPopup, setIsCardPopup] = useState(false);
   const [selectedCard, setSelectedCard] = useState({});

   const matches = useMediaQuery("(max-width:600px)");
   const matchesBigScreen = useMediaQuery("(max-width:1900px)");

   const wwr = useRef(null);
   const executeScrollWWR = () => wwr?.current.scrollIntoView();
   const Partners = useRef(null);
   const executeScrollPartners = () => Partners?.current.scrollIntoView();
   const AboutUs = useRef(null);
   const executeScrollAboutUs = () => AboutUs?.current.scrollIntoView();

   const handleCardClick = (item) => {
      setIsCardPopup(true);
      setSelectedCard(item);
   };

   return (
      <>
         <div>
            <div className={styles["home"]}>
               <NavbarComp
                  executeScrollPartners={executeScrollPartners}
                  executeScrollWWR={executeScrollWWR}
                  executeScrollAboutUs={executeScrollAboutUs}
               />
               <Container>
                  <div className={styles["motto"]}>
                     <div className={styles["motto1"]}>THE ULTIMATE</div>
                     <div className={styles["motto2"]}>
                        DIGITAL SPORTS PLATFORM
                     </div>
                  </div>
                  <StoreButtons />
                  <Carousel
                     cols={3}
                     rows={1}
                     gap={10}
                     loop
                     hideArrow
                     containerClassName={styles["carousel"]}
                     style={{ width: 500 }}>
                     {React.Children.toArray(
                        Cards.map((item) => (
                           <Carousel.Item>
                              <EventCard
                                 onClick={() => handleCardClick(item)}
                                 item={item}
                                 Picture={item.picture}
                                 Title={item.title}
                                 Event={item.event}
                                 Date={item.date}
                              />
                           </Carousel.Item>
                        ))
                     )}
                  </Carousel>
               </Container>
            </div>

            {/* <div className={styles["Partners"]}>
            <div ref={Partners} className="PText">
               Our Partners
            </div>
            <div className={styles["PContainer"]}>
               <img className={styles["Image"]} src={wadidegla} alt="sponser" />
               <img className={styles["Image"]} src={Stars} alt="sponser" />
               <img className={styles["Image"]} src={char} alt="sponser" />
               <img className={styles["Image"]} src={GA} alt="sponser" />
               <img className={styles["Image"]} src={DA} alt="sponser" />
               <img className={styles["Image"]} src={compete} alt="sponser" />
            </div>
            <Button className={styles["Button"]}>BECOME A PARTNER</Button>
         </div> */}

            <div>
               <img
                  className={styles["cutting_edge"]}
                  src={img}
                  alt="cutting edge technology"
                  ref={wwr}
               />
            </div>

            {/* <div className={styles["Statistics"]}>
            <div className={styles["PText"]}>Our Statistics</div>
            <div className={styles["PContainer"]}>
               <div style={{ margin: 10 }}>
                  {" "}
                  <div className={styles["stats"]}>
                     <div className={styles["plusSign"]}>+</div>
                     <div className={styles["numbers"]}>1</div>
                     <div className={styles["numbers"]}>1</div>
                     <div className={styles["numbers"]}>1</div>
                  </div>
                  <div className={styles["statDesc"]}>
                     Number of full matches with video analysis
                  </div>
               </div>

               <div style={{ margin: 10 }}>
                  {" "}
                  <div className={styles["stats"]}>
                     <div className={styles["plusSign"]}>+</div>
                     <div className={styles["numbers"]}>1</div>
                     <div className={styles["numbers"]}>1</div>
                     <div className={styles["numbers"]}>1</div>
                  </div>
                  <div className={styles["statDesc"]}>
                     Number of full matches with video analysis
                  </div>
               </div>
               <div style={{ margin: 10 }}>
                  {" "}
                  <div className={styles["stats"]}>
                     <div className={styles["plusSign"]}>+</div>
                     <div className={styles["numbers"]}>1</div>
                     <div className={styles["numbers"]}>1</div>
                     <div className={styles["numbers"]}>1</div>
                  </div>
                  <div className={styles["statDesc"]}>
                     Number of full matches with video analysis
                  </div>
               </div>
            </div>
         </div> */}

            <div className={styles["foto"]}>
               <img src={Image} className={styles["sora"]} alt="scoutx" />
               <div
                  style={{
                     position: !matches ? "absolute" : "relative",
                     right: !matches ? "10%" : "0",
                     top: "10%",
                     margin: "auto",
                  }}>
                  <StoreButtons />
               </div>
            </div>

            <Container>
               <Row ref={AboutUs} className={styles["vision"]}>
                  <Col lg={8}>
                     <div className={styles["vision2"]}>
                        <h2 className={styles["visionSubTitle"]}>
                           Built for Academies
                        </h2>
                        <p className={styles["visionParagraph"]}>
                           Monitor all your academy activities in one place.
                           Follow up on your players' progress, coaches’
                           activities and get feedback from the parents. Enjoy
                           the reporting tools we provide both on players'
                           engagement and business growth.
                        </p>
                     </div>
                  </Col>
                  <Col lg={4} className="d-flex justify-content-center">
                     <img
                        src={PlayerCard}
                        className={styles["playerCard"]}
                        alt="player card"
                     />
                  </Col>
               </Row>
            </Container>

            <Row
               className="m-0"
               style={{
                  minHeight: "100vh",
               }}>
               <Col lg={6} className="p-0">
                  <img src={mission} className={styles["missionImage"]} />
               </Col>
               <Col lg={6}>
                  <Container
                     style={{
                        paddingInline: !matchesBigScreen ? "10%" : 16,
                     }}>
                     <Text
                        primary
                        bold
                        fSize={55}
                        wrap={false}
                        textStyle={{
                           textTransform: "uppercase",
                        }}>
                        Go digital!
                     </Text>
                     <ul>
                        <li>Evaluate your players</li>
                        <li>Send Pre-instructions</li>
                        <li>Record Attendance</li>
                        <li>View Player stats</li>
                        <li>View Team stats</li>
                     </ul>
                  </Container>
               </Col>
            </Row>

            {/* <Plans /> */}
            {/* <Team /> */}
            <Footer />
         </div>

         <TransitionGroup>
            {isCardPopup && (
               <CSSTransition key={1} timeout={1000} classNames="fade-item">
                  <PopUp
                     data={selectedCard}
                     close={() => {
                        setIsCardPopup(false);
                     }}
                  />
               </CSSTransition>
            )}
         </TransitionGroup>
      </>
   );
}

export default Landing;
