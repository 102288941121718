import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Chips from "../../../../components/chips/Chips";
import TabContent from "../../../../components/tabs/TabContent";
import VideoIcon from "../../../../assets/images/icons/videoIcon.svg";
import styles from "../../../../styles/profile.module.css";
import Post from "../../../../components/cards/post/Post";
import { Services } from "../../../../apis/Services/Services";
import AppContext from "../../../../contexts/AppContext";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import LoadMore from "../../../../components/loadmore/LoadMore";
import EmptyComponent from "../../../../components/EmptyComponent/EmptyComponent";

export default function VideoTab({ activeTab }) {
   const { appState } = React.useContext(AppContext);
   const [videos, setVideos] = React.useState([]);
   const [moreData, setMoreData] = React.useState([]);

   const [categories, setCategories] = React.useState([]);
   const [selectedCategory, setSelectedCategory] = React.useState("");
   const [loading, setLoading] = React.useState(false);

   const [setError] = useHandleErrors();

   useEffect(() => {
      getVideos();
      getCategories();
   }, []);

   const getCategories = async () => {
      await Services.get("evaluation-attributes/", true)
         .then((res) => {
            setCategories([{ id: "", name: "All", category: null }, ...res]);
         })
         .catch(setError);
   };

   const getVideos = async (category = selectedCategory) => {
      setLoading(true);
      await Services.get(
         `posts/?type=video&user=${appState.data?.id}&evaluation_tags=${category}&ordering=-id`,
         true
      )
         .then(async (res) => {
            setMoreData(res);
            setVideos(res.results);
         })
         .catch(setError)
         .finally(() => setLoading(false));
   };

   const getMoreData = (res) => {
      setMoreData(res);
      setVideos([...res.results]);
   };

   return (
      <TabContent value={activeTab} index={1}>
         <Chips data={categories} handleActive={getVideos} />

         <Link
            to="/app/add-video"
            style={{
               textDecoration: "none",
            }}>
            <div
               className="py-3"
               style={{
                  display: "inline-block",
               }}>
               <img
                  src={VideoIcon}
                  width={40}
                  height={20}
                  style={{
                     objectFit: "contain",
                  }}
                  alt="add video"
               />
               <span className={styles.addVideoLink}>+Add new</span>
            </div>
         </Link>

         {videos.length !== 0 ? (
            <>
               <Row>
                  {React.Children.toArray(
                     videos.map((item) => (
                        <Col lg={4}>
                           <Post item={item} />
                        </Col>
                     ))
                  )}
               </Row>
               <LoadMore
                  next={moreData.next}
                  prev={moreData.previous}
                  newData={getMoreData}
               />
            </>
         ) : (
            <EmptyComponent text="No Videos available" loading={loading} />
         )}
      </TabContent>
   );
}
