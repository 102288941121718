import * as React from "react";
import { Chip, Stack } from "@mui/material";

export default function MultiSelect({ data, values, onSelectItems }) {
   const [selectedItems, setselectedItems] = React.useState([]);

   React.useEffect(() => {
      const bootstrapAsync = async () => {
         setselectedItems(values);
      };

      bootstrapAsync();
   }, []);

   const onSelectItem = (item) => {
      const filteredArr = [...selectedItems];
      let index = filteredArr.findIndex((selected) => selected === item);

      checkItem(item) ? filteredArr.splice(index, 1) : filteredArr.push(item);

      setselectedItems(filteredArr);
      onSelectItems(filteredArr);
   };

   const checkItem = (item) => {
      let check = selectedItems.some((selected) => selected === item);
      return check;
   };

   return (
      <Stack
         direction={{ xs: "column", sm: "row" }}
         className="my-3 flex-wrap"
         spacing={2}>
         {React.Children.toArray(
            data.map((item) => (
               <Chip
                  label={item.name}
                  onClick={() => onSelectItem(item.id)}
                  sx={{
                     color: checkItem(item.id) ? "#fff" : "#2C542A",
                     fontFamily: "Nexa Light",
                     backgroundColor: checkItem(item.id)
                        ? "#2C542A"
                        : "#E1E6E0",
                     border: "none",
                     borderTopLeftRadius: 5,
                     borderBottomLeftRadius: 5,
                     borderTopRightRadius: 5,
                     borderBottomRightRadius: 5,
                  }}
                  style={{
                     marginBlock: 2,
                  }}
               />
            ))
         )}
      </Stack>
   );
}
