import React from "react";
import "../styles/popup.css";
import StoreButtons from "./StoreButtons/StoreButtons";

function PopUp({ close, data }) {
   return (
      <section
         className="popup"
         onClick={(e) => {
            e.target.tagName === "SECTION" && close();
         }}>
         <div className="container">
            <div className="scrollDiv">
               <img
                  src={data.popupPic ?? data.picture}
                  className="article-img"
                  alt="post pic"></img>

               <div className="info">
                  <h1>
                     {data.title} {data.event}
                  </h1>
                  <p>
                     {data.upperText}
                     <br />
                     <br />
                     <StoreButtons width={150} />
                     <br />
                     {data.description}
                  </p>
                  <span className="date">{data.date}</span>
               </div>
               <span className="icon" onClick={close}>
                  &#x2715;
               </span>
            </div>
         </div>
      </section>
   );
}

export default PopUp;
