import { ReactComponent as Home } from "../assets/imgs/Home.svg";
import { ReactComponent as Academy } from "../assets/imgs/academyIcon.svg";
import { ReactComponent as Calendar } from "../assets/imgs/calendarIcon.svg";
import { ReactComponent as More } from "../assets/imgs/moreIcon.svg";
import { ReactComponent as User } from "../assets/imgs/userIcon.svg";
import Megaphone from "../assets/images/icons/megaphone.svg";
import Group from "../assets/images/icons/group.svg";
import Video from "../assets/images/icons/videoIcon.svg";

const Icons = {
   Home,
   Academy,
   Calendar,
   More,
   User,
   Megaphone,
   Group,
   Video
};

export default Icons;
