import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/system";

function a11yProps(index) {
   return {
      id: `TabContent-${index}`,
      "aria-controls": `TabContent-${index}`,
   };
}

const StyledTabs = styled((props) => (
   <Tabs
      {...props}
      TabIndicatorProps={{
         children: <span className="MuiTabs-indicatorSpan" />,
      }}
   />
))({
   "& .MuiTabs-indicator": {
      display: "flex",
      paddingLeft: 16,
      backgroundColor: "transparent",
   },
   "& .MuiTabs-indicatorSpan": {
      maxWidth: 50,
      width: "100%",
      backgroundColor: "#ED6623",
   },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
   textTransform: "none",
   fontFamily: "NEXA Light",
   fontWeight: 900,
   color: "#5A5A5A",
   "&.Mui-selected": {
      color: "#ED6623",
   },
}));

export default function TabsComponent({ tabs, value, handleChange }) {
   return (
      <Box>
         <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            {React.Children.toArray(
               tabs.map((item, index) => (
                  <StyledTab label={item} {...a11yProps(index)} />
               ))
            )}
         </StyledTabs>
      </Box>
   );
}
