import React from "react";
import Box from "@mui/material/Box";

export default function TabContent(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`TabContent-${index}`}
         aria-labelledby={`TabContent-${index}`}
         {...other}>
         {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
   );
}
