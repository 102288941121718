import React from "react";
import { toast } from "react-toastify";
toast.configure();

export default function useHandleErrors() {
   const [err, setErrorObj] = React.useState(null);

   React.useEffect(() => {
      if (err) {
         console.log("from useError", JSON.stringify(err));
         if (err.detail) {
            toast.error(err.detail);
         } else if (err.details) {
            toast.error(err?.details[0]);
         } else if (err.message) {
            toast.error(err.message);
         } else if (err.email) {
            toast.error(err.email[0]);
         } else if (err.code) {
            toast.error(err.code[0]);
         } else if (err.email) {
            toast.error(err.email[0]);
         } else if (err.non_field_errors) {
            toast.error(err.non_field_errors[0]);
         } else {
            toast.error("Something went wrong.");
         }
      }
   }, [err]);

   const setError = (data) => setErrorObj(data);

   return [setError];
}
