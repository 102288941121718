import carouselball from "../assets/imgs/CarouselBall.png";
import Card2 from "../assets/landingPage/card2.jpeg";
import Card3 from "../assets/landingPage/card3.jpeg";
import postImg from "../assets/imgs/post.png";

const Cards = [
   {
      title: "ScoutX",
      event: "Application is now live",
      date: "5 Jan, 2022",
      picture: carouselball,
      popupPic:postImg,
      upperText:
         "ScoutX Limited is happy to announce the launch of ScoutX application both on IOS and Android stores!",
      description: `ScoutX application is a digital platform built to enable digital transformation for sports academies and clubs, and improve interactions and engagement between players, parents, coaches, & academy admins. ${"\n"}
      
      The application features are tailored to provide the best digital experience for players who can enjoy a social-media-like interface posting videos and getting Feedback from their teammates, coaches, and parents. The players can easily track their progress, get instructions from the coaches, and plan their training sessions & matches calendar. Also, players can get technical and nutritional tips directly from their coaches and from ScoutX experts.

      Parents can use the ScoutX app to follow the progress of their children and interact with coaches & other parents. ScoutX also provides parents with a payment gateway to pay their children academy fees.

      Coaches will be able to organize their training schedules using the application, update the calendar with upcoming matches & trainings, send instructions to their players, and reward them after good performances.

      Academy admins will have the tool to monitor all the activities in one place. They will be able to monitor players progress, coaches’ activities and get feedback from the parents. The application will also enable them to communicate announcements. ScoutX will also provide the academy admins with the right tools to build teams, add players, & assign coaches and many other administrative features.

      This is just the first step, stay tuned for what will come next!`,
   },
   {
      title: "Reimaging Sports",
      event: "with disruptive technologies",
      date: "5 Jan, 2022",
      picture: Card2,
      description: `Technology is continuously evolving at a tremendous pace. More and more business use cases are being induced and enabled by Technology. The early signs of the Fourth industrial revolution are being observed in automation, Artificial intelligence, & data exchange. Some industries have benefited from the technological wave, such as Telecommunication, Mining, Healthcare, and the Economy. In contrast, others, such as sports, are still considered a green field with vast potential and great opportunities.

      Technology will have a mission in sports to spread the joy of the game to a broader audience and evolve the way audiences interact with the media. Digital platforms in the game provide the content and premium services that legacy media providers can’t offer. Telecom Service providers are positioning the 5G and the Edge technology to provide the best digital experience for their customers. A lot of partnerships are happening between CSPs and Content providers.

      Many recent announcements have raised the bar for an innovative digital sports era. Experts expect that 5G technology will change how football fans follow the games. With the evolution of Connectivity, loyal fans can book virtual stadium tickets via Augmented reality technology enabled by 5G. The audience can view real-time stats of their beloved players while watching the game, using the wearable sensors by the players. A new paradigm of opportunities is to be enabled by the rise of IoT, the Internet of things.

      At ScoutX, we are on a mission to bring the latest technologies into the sports field, specifically football. Our passion for sports and Technology is what pushes us forward.`,
   },
   {
      title: "Impact of technology",
      event: "on players development",
      date: "5 Jan, 2022",
      picture: Card3,
      description: `Technology is now involved in all aspects in football, from providing player stats to heat maps and GPS trackers, technology is key in developing young talents. Here are the latest technology tools that can be helpful for academy coaches:
      
      - Stats analytics: 
      
      Statistical analysis can be very useful for coaches to analyze the strengths and weakness of players, based on stats, coaches can help players to maximize their potential, work on the areas for development or even change their position on the field.
      
      Stats can vary from simple data like goals, assists, successful tackles and saves, to deeper stats like distance covered, major chances created.
      
      - GPS Trackers:
      
      GPS vests are a great way of continually monitoring and evaluating your players’ physical load. Although most of these technologies are predominantly used for professional players, products like Sock IT and Adidas GMR can be useful and engaging tools to be used for young players in a fun and engaging way.
      
      - Video Analysis:
      
      Video analysis software are now widely used across all levels of football to help coaches support their players and teams. It can provide an interactive tool for coaches to highlight and analyse the players and team performance. Video analysis is key to study opponents before games. Children often learn new skills from recreating goals and moves that their role models and this can be a great way to offer a simple platform for children to engage in some form of video analysis.`,
   },
];

export default Cards;
