import { Form, Upload } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Services } from "../../../../apis/Services/Services";
import ButtonComponent from "../../../../components/buttonComponent/ButtonComponent";
import Header from "../../../../components/header/Header";
import InputComp from "../../../../components/inputs/InputComp";
import SelectComponent from "../../../../components/inputs/SelectComponent";
import Nationalites from "../../../../constants/Nationalities";
import AppContext from "../../../../contexts/AppContext";
import useHandleErrors from "../../../../hooks/useHandleErrors";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
toast.configure();

function beforeUpload(file) {
   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
   if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");
   }
   const isLt2M = file.size / 1024 / 1024 < 2;
   if (!isLt2M) {
      toast.error("Image must smaller than 2MB!");
   }
   return isJpgOrPng && isLt2M;
}

export default function EditProfile() {
   const [setError] = useHandleErrors();
   const { appState, appDispatch } = React.useContext(AppContext);
   const [isLoading, setIsLoading] = React.useState(false);

   const onFinish = async (values) => {
      console.log(values);
      //   setIsLoading(true);
      //   await Services.set(values, "login/")
      //      .then((res) => {
      //         appDispatch(signedIn(res));
      //         navigate("/app/", { replace: true });
      //         localStorage.setItem("scout_token", res.access);
      //         localStorage.setItem("scout_userType", res.user_type);
      //      })
      //      .catch(setError)
      //      .finally(() => setIsLoading(false));
   };

   const handleUpdatePic = async (values) => {
      console.log(values);
      //   setIsLoading(true);
      //   await Services.set(values, "login/")
      //      .then((res) => {
      //         appDispatch(signedIn(res));
      //         navigate("/app/", { replace: true });
      //         localStorage.setItem("scout_token", res.access);
      //         localStorage.setItem("scout_userType", res.user_type);
      //      })
      //      .catch(setError)
      //      .finally(() => setIsLoading(false));
   };

   return (
      <div>
         <Header text="Edit" />
         <Row>
            <Col
               lg={4}
               className="d-flex justify-content-start flex-column align-items-center">
               <div className="photo">
                  <img
                     style={{
                        objectFit: "cover",
                        width: 180,
                        height: 180,
                        borderRadius: "50%",
                     }}
                     src={appState.data?.picture}
                     alt="user-pic"
                  />
                  <Upload
                     name="picture"
                     listType="picture-card"
                     className="avatar-uploader"
                     showUploadList={false}
                     beforeUpload={beforeUpload}
                     customRequest={handleUpdatePic}>
                     <div className="upload-photo pointer">
                        Upload
                        <br /> photo
                     </div>
                  </Upload>
               </div>

               <Link to="/app/profile">
                  <div
                     className="my-5"
                     style={{
                        textDecoration: "underline",
                        fontFamily: "Nexa Bold",
                        color: "#ED6623",
                     }}>
                     Change password
                  </div>
               </Link>
            </Col>
            <Col lg={8}>
               <Form
                  onFinish={onFinish}
                  initialValues={{
                     first_name: appState.data?.first_name,
                     ...appState.data,
                  }}>
                  <InputComp
                     required
                     name="first_name"
                     placeholder="First Name"
                  />
                  <InputComp
                     required
                     name="last_name"
                     placeholder="Last Name"
                  />

                  <InputComp required name="nickname" placeholder="Nickname" />

                  <SelectComponent
                     required
                     name="gender"
                     placeholder="Gender"
                     data={[
                        { id: "male", name: "Male" },
                        { id: "female", name: "Female" },
                     ]}
                  />
                  <InputComp required name="email" placeholder="Email" />
                  <InputComp required name="phone" placeholder="Phone" />

                  <InputComp
                     required
                     name="birth_date"
                     placeholder="Date of Birth"
                  />

                  <SelectComponent
                     required
                     name="nationality"
                     placeholder="Nationality"
                     data={Nationalites.map((item) => {
                        return { id: item, name: item };
                     })}
                  />

                  <InputComp
                     required
                     name="shirt_number"
                     placeholder="ShirtNo"
                  />

                  {/* <InputComp
                     required
                     name="shirt_number"
                     placeholder="Primary position"
                  />

                  <InputComp
                     required
                     name="shirt_number"
                     placeholder="Secandary position"
                  /> */}

                  <SelectComponent
                     required
                     name="preferred_foot"
                     placeholder="Preferred foot"
                     data={[
                        { name: "Right Foot", id: "right_foot" },
                        { name: "Left Foot", id: "left_foot" },
                     ]}
                  />

                  <InputComp required name="weight" placeholder="Weight" />

                  <InputComp required name="height" placeholder="Height" />

                  <InputComp
                     required
                     name="dream_team"
                     placeholder="Dream team"
                  />

                  <InputComp
                     required
                     name="role_model"
                     placeholder="Role model"
                  />

                  <ButtonComponent text="CONFIRM" isLoading={isLoading} />
               </Form>
            </Col>
         </Row>
      </div>
   );
}
