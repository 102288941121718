import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function Chips({ data ,handleActive}) {
   const [active, setActive] = React.useState('');

   const handleClick = (e) => {
      setActive(e);
      handleActive(e)
   };

   return (
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{
         maxHeight:150,
         overflow:'auto',
         pb:2
      }}>
         {React.Children.toArray(
            data.map((item) => (
               <Chip
                  label={item.name}
                  onClick={() => handleClick(item.id)}
                  sx={{
                     color: active === item.id ? "#fff":"#696969",
                     fontFamily: "Nexa Light",
                     backgroundColor:
                        active === item.id ? "#ED6623" : "#EFEFEF",
                     border: "none",
                     borderTopLeftRadius:5,
                     borderBottomLeftRadius:5,
                     borderTopRightRadius:5,
                     borderBottomRightRadius:5,
                     '$ .MuiButtonBase-root:hover':{
                        backgroundColor:"red"
                     }
                  }}
               />
            ))
         )}
      </Stack>
   );
}
