import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import Images from "../../constants/images";
import styles from "./nav.module.css";

function NavbarComp({
   executeScrollWWR,
   executeScrollPartners,
   executeScrollAboutUs,
}) {
   return (
      <Container>
         <Navbar bg="white" expand="lg" className={styles.navbar}>
            <Container>
               <Navbar.Brand href="#home">
                  <img
                     src={Images.coloredBrandLogo}
                     className={styles.brandLogo}
                     alt="ScoutX Logo"
                  />
               </Navbar.Brand>
               <div>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                     <Nav className="me-auto">
                        <Nav.Link onClick={executeScrollWWR}>
                           ScoutX app
                        </Nav.Link>
                        {/* <Nav.Link onClick={executeScrollPartners}>
                           Our partners
                        </Nav.Link> */}
                        <Nav.Link onClick={executeScrollAboutUs}>
                           About us
                        </Nav.Link>
                        {/* <Nav.Link>Packages</Nav.Link>
                        <Nav.Link>Meet the team</Nav.Link> */}
                     </Nav>
                  </Navbar.Collapse>
               </div>
            </Container>
         </Navbar>
      </Container>
   );
}

export default NavbarComp;
