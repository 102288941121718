const SET_DATA = "SET_DATA";
const SET_DETAILS = "SET_DETAILS";

const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const signedIn = (setUserToken) => {
   return {
      type: SIGN_IN,
      payload: setUserToken,
   };
};

const logout = () => {
   return {
      type: SIGN_OUT,
      payload: null,
   };
};

const setData = (state) => {
   return {
      type: SET_DATA,
      payload: state,
   };
};

const setDetails = (state) => {
   return {
      type: SET_DETAILS,
      payload: state,
   };
};

export { setData, setDetails, signedIn, logout };
