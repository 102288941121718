import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { Row } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";

import { Services } from "../../apis/Services/Services";
import useHandleErrors from "../../hooks/useHandleErrors";
import { Button, ButtonGroup } from "@mui/material";

const antIcon = (
   <CircularProgress
      style={{
         color: "white",
         width: 30,
         height: 30,
      }}
   />
);

function LoadMore(props) {
   const [isUrl, setIsUrl] = useState(props.next);
   const [isPrevUrl, setIsPrevUrl] = useState(props.prev);

   const [isLoading, setIsLoading] = useState(false);
   const [setError] = useHandleErrors();

   useEffect(() => {
      setIsUrl(props.next);
      setIsPrevUrl(props.prev);
   }, [props.next]);

   const loadMoreData = (url) => {
      if (url) {
         setIsLoading(true);
         Services.get_next_page(url, true)
            .then((res) => {
               props.newData(res);
               setIsUrl(res.next);
               setIsPrevUrl(res.previous);
               setIsLoading(false);
            })
            .catch(setError);
      }
   };

   return (
      <div className="py-3 text-center">
         {isLoading ? (
            <Row className="justify-content-center">
               <Spin indicator={antIcon} tip="Loading..." size="large" />
            </Row>
         ) : (
            <ButtonGroup
               variant="contained"
               aria-label="outlined primary button group">
               <Button
                  disabled={!isPrevUrl}
                  onClick={() => loadMoreData(isPrevUrl)} sx={{
                      backgroundColor:"#ed6623",
                      borderColor:"#ed6623"
                  }}>
                  Prev
               </Button>
               <Button disabled={!isUrl} onClick={() => loadMoreData(isUrl)} sx={{
                   backgroundColor:"#ed6623",
                   borderColor:"#ed6623"
               }}>
                  Next
               </Button>
            </ButtonGroup>
         )}
      </div>
   );
}

export default LoadMore;
