import React from "react";
import { Button } from "antd";
import styles from "./button.module.css";
import CircularProgress from "@mui/material/CircularProgress";

export default function ButtonComponent({
   isLoading,
   text,
   handlePress,
   buttonTwo,
   btnStyle,
}) {
   return isLoading ? (
      <div className={[styles.button]}>
         <CircularProgress
            style={{
               color: "white",
               width: 30,
               height: 30,
            }}
         />
      </div>
   ) : (
      <Button
         type="primary"
         htmlType="submit"
         onClick={handlePress}
         // buttonTwo={buttonTwo}
         className={buttonTwo ? styles.button2 : styles.button}
         style={{
            ...btnStyle,
         }}>
         {text}
      </Button>
   );
}
