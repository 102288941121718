import React, { useEffect } from "react";
import { Card, CardContent, CardMedia, useMediaQuery } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { AiOutlineLike, AiFillLike, AiOutlineShareAlt } from "react-icons/ai";
import styles from "../popups.module.css";
import CommentItem from "./CommentItem";
import Popup from "../Popup";
import { Services } from "../../../apis/Services/Services";
import useHandleErrors from "../../../hooks/useHandleErrors";
import InputComp from "../../inputs/InputComp";
import ButtonComponent from "../../buttonComponent/ButtonComponent";
import { Form } from "antd";
import Text from "../../Text";

export default function PostPopup({
   item,
   isOpened,
   setIsOpened,
   likesCount,
   toggleLike,
   isLiked,
}) {
   const [isPostLiked, setIsLiked] = React.useState(isLiked);
   const [comments, setComments] = React.useState([]);
   const [isLoading, setIsLoading] = React.useState(false);
   const matches = useMediaQuery("(min-width:600px)");
   const [setError] = useHandleErrors();
   const commentFormRef = React.useRef();

   useEffect(() => {
      setIsLiked(isLiked);
   }, [isLiked]);

   useEffect(() => {
      isOpened && getComments();
   }, [isOpened]);

   const getComments = () => {
      Services.get(`comments/?post=${item.id}&page_size=10`, true)
         .then((res) => {
            setComments(res.results);
         })
         .catch(setError);
   };

   const addComment = async (values) => {
      let data = { ...values };
      data.post = item.id;
      setIsLoading(true);
      await Services.set(data, `comments/`, true)
         .then((res) => {
            commentFormRef.current.resetFields();
            setComments((old) => [...old, res]);
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };

   return (
      <Popup isOpened={isOpened} setIsOpened={setIsOpened}>
         <Card
            className={styles.postCard}
            sx={{
               width: matches ? 650 : "100%",
               borderRadius: 0,
               boxShadow: "none",
            }}>
            {item.picture ? (
               <CardMedia
                  component={"img"}
                  height="180"
                  src={
                     item.picture ??
                     "https://gskpro.com/etc/designs/zg-placeholders/video.png"
                  }
                  style={{
                     borderRadius: 10,
                  }}
                  alt={item.title}
               />
            ) : (
               <CardMedia
                  component={item.video ? "video" : "img"}
                  height="140"
                  src={
                     item.video ??
                     "https://gskpro.com/etc/designs/zg-placeholders/video.png"
                  }
                  alt={item.title}
               />
            )}

            <CardContent className="pt-3 px-0">
               <Text bold primary fSize={19}>
                  {item.title}
               </Text>
               <Text fSize={14}>
                  <Text
                     bold
                     primary
                     textStyle={{
                        display: "inline",
                        paddingRight: 5,
                     }}>
                     By
                  </Text>
                  {item.user_obj.username} ({item.user_obj.user_type})
               </Text>

               <Row className="align-items-center mx-0">
                  <Col lg={3}>
                     <div className={styles.actions}>
                        <div
                           className={styles.item}
                           onClick={() => toggleLike()}>
                           {isPostLiked ? (
                              <AiFillLike
                                 style={{
                                    fontSize: 20,
                                    color: "#ED6623",
                                 }}
                              />
                           ) : (
                              <AiOutlineLike
                                 style={{
                                    fontSize: 20,
                                    color: "#ED6623",
                                 }}
                              />
                           )}
                        </div>
                        <div className={styles.item}>
                           <AiOutlineShareAlt
                              style={{
                                 fontSize: 20,
                                 color: "#ED6623",
                              }}
                           />
                        </div>
                     </div>
                  </Col>
                  <Col className="p-0">
                     <Row>
                        <Col>
                           <Text primary fSize={14}>
                              {likesCount} <span>LIKES</span>
                           </Text>
                        </Col>
                        <Col>
                           <Text primary fSize={14}>
                              {comments.length} <span>COMMENTS</span>
                           </Text>
                        </Col>
                     </Row>
                     <div className={styles.tags}>
                        <span>TAGS:</span> {item.tags.map((tag) => tag + " , ")}
                     </div>
                  </Col>
               </Row>

               {React.Children.toArray(
                  comments.map((item) => <CommentItem item={item} />)
               )}

               <Form onFinish={addComment} ref={commentFormRef}>
                  <InputComp
                     required
                     textarea
                     name="text"
                     placeholder="Comment"
                  />
                  <Row>
                     <Col lg={4}>
                        <ButtonComponent
                           text="POST COMMENT"
                           btnStyle={{
                              fontSize: 18,
                           }}
                           isLoading={isLoading}
                        />
                     </Col>
                  </Row>
               </Form>
            </CardContent>
         </Card>
      </Popup>
   );
}
