import React from "react";
import { Typography } from "@mui/material";

export default function Text(props) {
   const textStyle = {
      color: props.primary
         ? "#ED6623"
         : props.secondary
         ? "#2c542a"
         : "#696969",
      fontSize: props.fSize ?? 16,
      fontFamily: props.bold ? "Nexa Bold" : "Nexa Light",
   };

   return (
      <Typography
         gutterBottom={props.marginBottom}
         noWrap={props.wrap ?? true}
         variant="h5"
         component="div"
         style={{ ...textStyle, ...props.textStyle }}
         className={props.textClass?props.textClass:""}>
         {props.children}
      </Typography>
   );
}
