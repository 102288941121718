import { ENDPOINTS } from "../EndPoints/Endpoints";
import { Network } from "../Network";

export class Services {
   static get(params, isAuth = false) {
      return Network.fetch(
         ENDPOINTS.get.url(params),
         {
            method: ENDPOINTS.get.method,
         },
         isAuth
      );
   }

   static get_next_page(url, isAuth = false) {
      return Network.fetch(
         url,
         {
            method: ENDPOINTS.get.method,
         },
         isAuth
      );
   }

   static set(values, params, isAuth = false) {
      return Network.fetch(
         ENDPOINTS.set.url(params),
         {
            body: JSON.stringify(values),
            headers: {
               "Content-Type": "application/json",
            },
            method: ENDPOINTS.set.method,
         },
         isAuth
      );
   }

   static patchForm(values, params, isAuth = false) {
      const data = new FormData();

      Object.keys(values).forEach((key) => {
         data.append(key, values[key]);
      });

      return Network.fetch(
         ENDPOINTS.patch.url(params),
         {
            body: data,
            method: ENDPOINTS.patch.method,
         },
         isAuth
      );
   }

   static setForm(values, params, isAuth = false) {
      const data = new FormData();

      Object.keys(values).forEach((key) => {
         data.append(key, values[key]);
      });

      return Network.fetch(
         ENDPOINTS.set.url(params),
         {
            body: data,
            method: ENDPOINTS.set.method,
         },
         isAuth
      );
   }
}
