import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "../../../styles/auth.module.css";
import InputComp from "../../../components/inputs/InputComp";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { Services } from "../../../apis/Services/Services";

function ForgotPassword() {
   const [isLoading, setIsLoading] = useState(false);
   const [setError] = useHandleErrors();
   const navigate = useNavigate();

   const onFinish = async (values) => {
      setIsLoading(true);
      await Services.set(values, "/user_password/send_reset_email/")
         .then((res) => {
            navigate("/auth/password-verification");
            localStorage.setItem("email", values.email);
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };
   return (
      <div>
         <div className={styles.header}>
            <div className={styles.authCodeTitle}>Forgot password?</div>
         </div>

         <Form onFinish={onFinish} className={styles.form}>
            <div className={styles.center}>
               <InputComp required name="email" placeholder="Email" />
            </div>
            <ButtonComponent text="Confirm" isLoading={isLoading} />
         </Form>
      </div>
   );
}

export default ForgotPassword;
