import { Typography } from "@mui/material";
import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "../cards.module.css";
import Flag from "../../../assets/images/flag.png";
import DefaultUser from "../../../assets/images/defaultUser.png";

const PlayerInfo = ({ data }) => {
   return (
      <div className={styles.player_info_card}>
         <Row className="justify-content-center align-items-center">
            <Col>
               <div>
                  <Typography
                     component="div"
                     variant="h5"
                     noWrap
                     style={{
                        fontFamily: "Nexa Bold",
                        color: "#fff",
                        fontSize: 18,
                     }}>
                     30
                  </Typography>
                  <img src={Flag} style={{ width: 25, height: 24 }} alt="country flag" />
               </div>
            </Col>
            <Col>
               {data?.show_profile_picture ? (
                  <img
                     src={data?.picture ?? DefaultUser}
                     style={{ width: 72, height: 72, objectFit: "cover",borderRadius:50 }}
                     alt={data?.first_name}
                  />
               ) : (
                  <img
                     src={DefaultUser}
                     style={{ width: 72, height: 72, objectFit: "cover",borderRadius:50 }}
                     alt={data?.first_name}
                  />
               )}
            </Col>
         </Row>
         <div className="text-center">
            <Typography
               component="div"
               variant="h5"
               noWrap
               style={{
                  fontFamily: "Nexa Bold",
                  color: "#fff",
                  fontSize: 14,
               }}>
               {data?.first_name}
            </Typography>
            <Typography
               component="div"
               variant="h5"
               noWrap
               style={{
                  fontFamily: "Nexa Light",
                  color: "#fff",
                  fontSize: 12,
               }}>
               ({data?.nickname})
            </Typography>
            <Typography
               component="div"
               variant="h5"
               noWrap
               style={{
                  fontFamily: "Nexa Bold",
                  color: "#fff",
                  fontSize: 12,
               }}>
               {data?.shirt_number}
            </Typography>
         </div>
      </div>
   );
};

export default PlayerInfo;
