import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "../../../styles/auth.module.css";
import InputComp from "../../../components/inputs/InputComp";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";
import useHandleErrors from "../../../hooks/useHandleErrors";
import { Services } from "../../../apis/Services/Services";

function PasswordVerification() {
   const [setError] = useHandleErrors();
   const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      let email = localStorage.getItem("email");
      setEmail(email);
   }, []);

   const onFinish = async (values) => {
      setIsLoading(true);
      values.email = email;
      await Services.set(values, "/user_password/check_reset_code/")
         .then((res) => {
            navigate("/auth/password-reset");
            localStorage.setItem("code", values.code);
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };
   return (
      <div>
         <div className={styles.header}>
            <div className={styles.authCodeTitle}>Verification Code</div>
         </div>

         <Form onFinish={onFinish} className={styles.form}>
            <div className={styles.center}>
               <InputComp required={true} name="code" placeholder="Code" />
            </div>
            <ButtonComponent text="Confirm" isLoading={isLoading} />
         </Form>
      </div>
   );
}

export default PasswordVerification;
