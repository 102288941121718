import * as React from "react";
import { Card, CardContent, CardMedia, CardActionArea } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import styles from "../cards.module.css";
import PostPopup from "../../popups/PostPopup/PostPopup";
import { Services } from "../../../apis/Services/Services";
import useHandleErrors from "../../../hooks/useHandleErrors";
import Text from "../../Text";

export default function Post({ item }) {
   const [isLiked, setIsLiked] = React.useState(item.liked);
   const [isOpened, setIsOpened] = React.useState(false);

   const [likesCount, setLikesCount] = React.useState(item.reactions_count);
   // const [commentsCount, setCommentsCount] = React.useState(0);
   const [setError] = useHandleErrors();

   React.useEffect(() => {}, []);

   const toggleLike = () => {
      Services.set({ id: item.id }, `posts/${item.id}/toggle_like/`, true)
         .then((res) => {
            setIsLiked(!isLiked);
            res.action === "liked"
               ? setLikesCount((old) => old + 1)
               : setLikesCount((old) => old - 1);
         })
         .catch(setError);
   };

   return (
      <>
         <Card
            className={styles.postCard}
            sx={{
               borderRadius: 2,
            }}>
            <div className={styles.actions}>
               <div className={styles.item} onClick={() => toggleLike()}>
                  {isLiked ? (
                     <AiFillLike
                        style={{
                           fontSize: 20,
                           color: "#ED6623",
                        }}
                     />
                  ) : (
                     <AiOutlineLike
                        style={{
                           fontSize: 20,
                           color: "#ED6623",
                        }}
                     />
                  )}
               </div>
               <div className={styles.item}>
                  <BiCommentDetail
                     style={{
                        fontSize: 20,
                        color: "#ED6623",
                     }}
                  />
               </div>
            </div>

            <CardActionArea onClick={() => setIsOpened(true)}>
               {item.picture ? (
                  <CardMedia
                     component={"img"}
                     height="180"
                     src={
                        item.picture ??
                        "https://gskpro.com/etc/designs/zg-placeholders/video.png"
                     }
                     alt={item.title}
                  />
               ) : (
                  <CardMedia
                     component={item.video ? "video" : "img"}
                     height="180"
                     src={
                        item.video ??
                        "https://gskpro.com/etc/designs/zg-placeholders/video.png"
                     }
                     alt={item.title}
                  />
               )}

               <CardContent className="pt-3">
                  <Text primary bold fSize={16}>
                     {item.title}
                  </Text>
                  <Text fSize={14}>
                     <Text
                        bold
                        primary
                        textStyle={{
                           display: "inline",
                           paddingRight: 5,
                        }}>
                        By
                     </Text>
                     {item.user_obj.username} ({item.user_obj.user_type})
                  </Text>
                  <Row>
                     <Col>
                        <Text primary fSize={14}>
                           {likesCount} <span>LIKES</span>
                        </Text>
                     </Col>
                     <Col>
                        <Text primary fSize={14}>
                           {item.comments_count} <span>COMMENTS</span>
                        </Text>
                     </Col>
                  </Row>
                  <div className={styles.tags}>
                     <span>TAGS:</span> {item.tags.map((tag) => tag + " , ")}
                  </div>
               </CardContent>
            </CardActionArea>
         </Card>
         <PostPopup
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            item={item}
            isLiked={isLiked}
            likesCount={likesCount}
            toggleLike={() => toggleLike()}
         />
      </>
   );
}
