import * as React from "react";
import { Box, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import Moment from "moment";
import AppContext from "../../../contexts/AppContext";
import Text from "../../Text";

const RedirectTo = ({ text, to }) => (
   <Link to={to}>
      <Text
         primary
         textStyle={{ textDecoration: "underline", marginBottom: 5 }}>
         {text}
      </Text>
   </Link>
);

export default function ScheduleCard({ item }) {
   const { appState } = React.useContext(AppContext);

   return (
      <Card
         sx={{
            display: "flex",
            boxShadow: "0px 0px 9px #00000029",
            borderRadius: 2,
            alignItems: "center",
            justifyContent: "space-around",
            marginBlock: 2,
         }}>
         <Box
            sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap" }}>
            <CardContent sx={{ flex: "1 0 auto", padding: 3 }}>
               <Text secondary bold fSize={18}>
                  {item?.match
                     ? `${item?.match_obj?.team_obj?.name} vs ${item?.event?.opponent_team_str}`
                     : `${Moment(item.date).format("dddd")} football training ${
                          item?.training_obj?.team_obj?.name
                       }`}
               </Text>
               {/* {item?.event.created_by == appUser?.data?.id && (
                     (Added by you)
                  )} */}
               <Text fSize={18}>
                  {`${Moment(item.from_time, ["h:mm A"]).format(
                     "HH:mm"
                  )} - ${Moment(item.to_time, ["h:mm A"]).format("HH:mm")} ${
                     item?.location
                  }`}
               </Text>

               <Text fSize={18} bold secondary>
                  {(appState.userType === "playeruser" ||
                     appState.userType === "parentuser") &&
                  item?.match
                     ? item?.attended
                        ? "Participated"
                        : ""
                     : item?.attended
                     ? "Attended"
                     : ""}
               </Text>
            </CardContent>
         </Box>
         <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* edit event btn */}
            {!item.passed && item.permissions.update && (
               <RedirectTo text="Edit" to="/" />
            )}
            {/* to pre-match */}
            {!item?.passed && appState.userType === "coachuser" && (
               <RedirectTo
                  text={
                     (item.training_obj &&
                        item?.training_obj?.pre_training_instructions) ||
                     item?.match_obj?.pre_match_instructions
                        ? "Edit instructions"
                        : "Send instructions"
                  }
                  to="/"
               />
            )}
            {/* Match Evaluations */}
            {item?.passed && appState.userType === "coachuser" && (
               <RedirectTo
                  text={
                     item?.training_obj?.evaluation_score ||
                     item?.match_obj?.evaluation_score
                        ? "Re-evaluate"
                        : "Evaluate"
                  }
                  to="/"
               />
            )}

            {/* Update Match */}
            {item?.match &&
               item?.passed &&
               (appState.userType === "adminuser" ||
                  appState.userType === "coachuser") && (
                  <RedirectTo text={"Update match info"} to="/" />
               )}

            {/* match record attendance && (eventType: "match") */}
            {item?.passed &&
               item?.match &&
               (appState.userType === "adminuser" ||
                  appState.userType === "coachuser") && (
                  <RedirectTo
                     text={
                        !item?.has_attendance ? "Select lineup" : "Edit lineup"
                     }
                     to="/"
                  />
               )}

            {/* training must change title there to record attendance  */}
            {item?.passed &&
               item?.training &&
               (appState.userType === "adminuser" ||
                  appState.userType === "coachuser") && (
                  <RedirectTo
                     text={
                        !item?.has_attendance
                           ? "Record attendance"
                           : "Edit attendanc"
                     }
                     to="/"
                  />
               )}

            {/* comment till client talk about it */}
            {/* <ButtonComponent
               text="View more info"
               btnStyle={{
                  fontSize: 14,
               }}
            /> */}
         </Box>
      </Card>
   );
}
