import React from "react";
import { Card, CardContent } from "@mui/material";
import Text from "../../Text";

export default function AnalyticCard({ label, count }) {
   return (
      <Card
         sx={{
            backgroundColor: "#EFEFEF",
            borderRadius: 1,
            width: 140,
            height: 145,
            boxShadow: "none",
            margin: 2,
         }}>
         <CardContent
            style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-between",
               height: "100%",
            }}>
            <Text fSize={14} primary wrap={false}>
               {label}
            </Text>
            <Text
               fSize={30}
               secondary
               bold
               textStyle={{
                  textAlign: "end",
               }}>
               {count}
            </Text>
         </CardContent>
      </Card>
   );
}
