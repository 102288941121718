import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import styles from "../../styles/auth.module.css";
import InputComp from "../../components/inputs/InputComp";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";
import useHandleErrors from "../../hooks/useHandleErrors";
import { Services } from "../../apis/Services/Services";

function SignupCode() {
   const [isLoading, setIsLoading] = useState(false);
   const [email, setEmail] = useState("");
   const [setError] = useHandleErrors();
   const navigate = useNavigate();

   useEffect(() => {
      let email = localStorage.getItem("email");
      setEmail(email);
   }, []);

   const onFinish = async (values) => {
      values.email = email;
      setIsLoading(true);
      await Services.set(values, "/email_verification/verify_email/")
         .then((res) => {
            navigate("/auth/signup-success");
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };
   return (
      <div>
         <div className={styles.header}>
            <div className={styles.authCodeTitle}>Thanks for signing up</div>
            <div className={styles.authCodeMsg}>
               We have sent an activation code to your email. Please check it
               out and enter below to activate your account.
            </div>
         </div>

         <Form
            initialValues={{
               remember: true,
            }}
            onFinish={onFinish}
            className={styles.form}>
            <div className={styles.center}>
               <InputComp
                  required={true}
                  name="code"
                  placeholder="Code"
                  style={{ marginBottom: 50 }}
               />
            </div>
            <ButtonComponent text="Confirm" isLoading={isLoading} />
         </Form>
      </div>
   );
}

export default SignupCode;
