import React from "react";
import { Col, Row } from "react-bootstrap";
import { Services } from "../../../apis/Services/Services";
import AcademyCard from "../../../components/cards/Academy/AcademyCard";
import LoadMore from "../../../components/loadmore/LoadMore";
import AcademyFilters from "../../../components/popups/AcademyFilters/AcademyFilters";
import TabsComponent from "../../../components/tabs/TabsComponent";
import Text from "../../../components/Text";
import useHandleErrors from "../../../hooks/useHandleErrors";

const tabs = [
   {
      name: "Players",
      api: "playerapp/players",
   },
   {
      name: "Coaches",
      api: "coachapp/coaches",
   },
   {
      name: "Teams",
      api: "teams",
   },
];

export default function AcademyData({ academyId }) {
   const [data, setData] = React.useState([]);
   const [moreData, setMoreData] = React.useState([]);
   const [activeTab, setActiveTab] = React.useState(0);

   const [isFiltersOpened, setFiltersOpened] = React.useState(false);

   const [setError] = useHandleErrors();

   React.useEffect(() => {
      getData(tabs[activeTab].api);
   }, [activeTab]);

   const handleChange = (event, newValue) => {
      setActiveTab(newValue);
   };

   const getData = (category) => {
      Services.get(`${category}/?academy=${academyId}`, true)
         .then((res) => {
            setData(res.results);
            setMoreData(res);
         })
         .catch(setError);
   };

   const getMoreData = (res) => {
      setMoreData(res);
      setData([...res.results]);
   };

   return (
      <div>
         <Row className="align-items-center">
            <Col lg={11}>
               <TabsComponent
                  tabs={tabs.map((item) => item.name)}
                  value={activeTab}
                  handleChange={handleChange}
               />
            </Col>
            {activeTab === 0 && (
               <Col lg={1} className="text-right">
                  <div
                     onClick={() => setFiltersOpened(true)}
                     style={{
                        cursor: "pointer",
                     }}>
                     <Text
                        bold
                        primary
                        textStyle={{
                           textDecoration: "underline",
                        }}>
                        Filters
                     </Text>
                  </div>
               </Col>
            )}
         </Row>

         <Row>
            {React.Children.toArray(
               data.map((item) => (
                  <Col lg={4}>
                     <AcademyCard item={item} type={tabs[activeTab].name} />
                  </Col>
               ))
            )}
         </Row>

         <LoadMore
            next={moreData.next}
            prev={moreData.previous}
            newData={getMoreData}
         />

         {/* filters modal */}
         <AcademyFilters
            isOpened={isFiltersOpened}
            setIsOpened={setFiltersOpened}
         />
      </div>
   );
}
