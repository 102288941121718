import React, { useState } from "react";
import styles from "../../styles/auth.module.css";
import { Form, DatePicker, Checkbox, Menu, Dropdown } from "antd";
import InputComp from "../../components/inputs/InputComp";
import { DownOutlined } from "@ant-design/icons";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";
import useHandleErrors from "../../hooks/useHandleErrors";
import { Link, useNavigate } from "react-router-dom";
import { Services } from "../../apis/Services/Services";
import { toast } from "react-toastify";
toast.configure();

function Signup() {
   const [isLoading, setIsLoading] = useState(false);
   const [setError] = useHandleErrors();
   const navigate = useNavigate();
   const [date, setDate] = useState("date");
   const [accountType, setAccountType] = useState();
   const onClick = ({ key }) => {
      setAccountType(key);
   };
   const menu = (
      <Menu onClick={onClick}>
         <Menu.Item key="player">Player</Menu.Item>
         <Menu.Item key="parent">Parent</Menu.Item>
         <Menu.Item key="coach">Coach</Menu.Item>
      </Menu>
   );

   const onFinish = async (values) => {
      setIsLoading(true);
      values.username = values.firstName;
      values.date = date;
      values.accountType = accountType;

      if (values.accountType !== "") {
         await Services.set(values, `${values.accountType}app/me/`)
            .then((res) => {
               navigate("/auth/signup-code");
               localStorage.setItem("email", values.email);
            })
            .catch(setError)
            .finally(() => setIsLoading(false));
      } else {
         toast.error("Please choose account type");
      }
   };
   function onChange(date, dateString) {
      setDate(dateString);
   }
   return (
      <div>
         <div className={styles.header}>
            <div className={styles.authTitle}>Sign Up</div>
            <div className={styles.account}>
               <div className={styles.noAcc}>Have an account?</div>
               <Link to="/auth">
                  <div className={styles.sign}>Sign In</div>
               </Link>
            </div>
         </div>

         <Form
            initialValues={{
               remember: true,
            }}
            onFinish={onFinish}
            className={styles.form}>
            <Form.Item
               rules={[
                  {
                     required: true,
                     message: "Please choose an account type",
                  },
               ]}
               className={styles.dropdown}>
               <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  className={styles.ddLink}>
                  <a
                     className="ant-dropdown-link"
                     onClick={(e) => e.preventDefault()}>
                     {accountType ? accountType : "Choose account type"}{" "}
                     <DownOutlined />
                  </a>
               </Dropdown>
            </Form.Item>
            <div className={styles.center}>
               <div
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     flexWrap: "wrap",
                  }}
                  className={styles.names}>
                  <InputComp
                     required
                     name="first_name"
                     placeholder="First Name"
                     itemStyle={{ width: "45%" }}
                  />
                  <InputComp
                     required
                     name="last_name"
                     placeholder="Last Name"
                     itemStyle={{ width: "45%" }}
                  />
               </div>

               <InputComp required name="email" placeholder="Username" />

               <InputComp
                  required
                  type="password"
                  name="password"
                  placeholder="Password"
               />
            </div>

            <DatePicker onChange={onChange} className={styles.input} />
            <div className={styles.remember}>
               <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className={styles.checkbox}>
                  <Checkbox>Terms and conditions</Checkbox>
               </Form.Item>
            </div>

            <ButtonComponent text="SIGN UP" isLoading={isLoading} />
         </Form>
      </div>
   );
}

export default Signup;
