import React from "react";
import { Grid } from "@mui/material";
import Images from "../../constants/images";

export default function StoreButtons({ width }) {
   return (
      <Grid container spacing={1}>
         <Grid item xs="auto">
            <a
               target="_blank"
               rel="noreferrer"
               href="https://apps.apple.com/eg/app/scoutx/id1594177766">
               <img
                  src={Images.appStore}
                  width={width ?? 156}
                  style={{
                     objectFit: "contain",
                  }}
                  alt="App store"
               />
            </a>
         </Grid>
         <Grid item xs="auto">
            <a
               target="_blank"
               rel="noreferrer"
               href="https://play.google.com/store/apps/details?id=com.bit68.scoutx">
               <img
                  src={Images.googleStore}
                  width={width ?? 156}
                  style={{
                     objectFit: "contain",
                  }}
                  alt="Google Store"
               />
            </a>
         </Grid>
      </Grid>
   );
}
