import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";
import { GrClose } from "react-icons/gr";
import { Services } from "../../../../apis/Services/Services";
import ButtonComponent from "../../../../components/buttonComponent/ButtonComponent";
import Header from "../../../../components/header/Header";
import InputComp from "../../../../components/inputs/InputComp";
import MultiSelect from "../../../../components/inputs/MultiSelect";
import CombineTabs from "../../../../components/tabs/CombineTabs";
import useHandleErrors from "../../../../hooks/useHandleErrors";

toast.configure();

const props = {
   name: "avatar",
   listType: "picture-card",
   className: "avatar-uploader",
   showUploadList: false,
};

const VideoProps = {
   beforeUpload(file) {
      const isVideo = file.type === "video/mp4";
      if (!isVideo) {
         toast.error("You can only upload video/mp4 file!");
      }
      return isVideo;
   },
};
const PictureProps = {
   beforeUpload(file) {
      const isJpgOrPng =
         file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
         toast.error("You can only upload JPG/PNG file!");
      }
      return isJpgOrPng;
   },
};

const getBase64 = (file, callback) => {
   if (file) {
      const reader = new FileReader();
      reader.addEventListener("loadend", () => callback(reader.result));
      reader.readAsDataURL(file);
   }
};

export default function AddPost() {
   const navigate = useNavigate();
   const params = useParams();

   const formRef = useRef();
   const [setError] = useHandleErrors();
   const [file, setFile] = useState(null);

   const [isLoading, setIsLoading] = useState(false);
   const [selectedCategory, setSelectedCategory] = React.useState(0);

   const [categories, setCategories] = useState([]);
   const [subCategories, setSubCategories] = useState([]);

   useEffect(() => {
      params.type === "video" && getCategories();
   }, []);

   const handleChange = (event, newValue) => {
      setSelectedCategory(newValue);
   };

   const getCategories = async () => {
      await Services.get("evaluation-attribute-categories/", true)
         .then((res) => {
            setCategories(res);
         })
         .catch(setError);
   };

   const onFinish = async (values) => {
      let data = { ...values };
      data.tags = data.mention.split(" ");
      data.type = params.type === "video" ? "video" : "post";
      data.evaluation_tags = subCategories;
      setIsLoading(true);
      await Services.set(data, "posts/", true)
         .then((res) => {
            addFile(res.id, values);
         })
         .catch(setError);
   };

   const addFile = async (id, values) => {
      let data = {};
      params.type === "video"
         ? (data.video = values.file.file.originFileObj)
         : (data.picture = values.file.file.originFileObj);
      await Services.patchForm(data, `posts/${id}/`, true)
         .then((res) => {
            navigate("/app");
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };
   return (
      <div>
         <Header text={`Add ${params.type === "video" ? "video" : "post"}`} />
         <Form ref={formRef} onFinish={onFinish}>
            <Row>
               <Col lg={5}>
                  <Form.Item
                     name="file"
                     rules={[
                        {
                           required: true,
                           message: "Please upload your file(s)",
                        },
                     ]}>
                     {file ? (
                        <div>
                           <IconButton
                              aria-label="close"
                              onClick={() => {
                                 setFile(null);
                                 formRef.current.setFieldsValue({
                                    file: null,
                                 });
                              }}
                              sx={{
                                 position: "absolute",
                                 right: 8,
                                 top: 8,
                                 backgroundColor: "white",
                                 zIndex: 12,
                              }}>
                              <GrClose
                                 style={{
                                    fontSize: 18,
                                 }}
                              />
                           </IconButton>
                           {params.type === "video" ? (
                              <video width="100%" height={200}>
                                 <source src={file} type="video/mp4" />
                              </video>
                           ) : (
                              <img
                                 src={file}
                                 width="100%"
                                 height={200}
                                 style={{
                                    borderRadius: 5,
                                    objectFit: "cover",
                                 }}
                                 alt="post image"
                              />
                           )}
                        </div>
                     ) : (
                        <Dragger
                           {...props}
                           beforeUpload={(file) => {
                              params.type === "video"
                                 ? VideoProps.beforeUpload(file)
                                 : PictureProps.beforeUpload(file);
                           }}
                           onChange={(info) => {
                              getBase64(
                                 info.file.originFileObj,
                                 (imageUrl) =>
                                    imageUrl &&
                                    (setFile(imageUrl),
                                    formRef.current.setFieldsValue({
                                       file: info,
                                    }))
                              );
                           }}
                           onRemove={(file) => {
                              setFile(null);
                              formRef.current.setFieldsValue({
                                 file: null,
                              });
                           }}>
                           <p className="ant-upload-drag-icon">
                              Upload{" "}
                              {`${params.type === "video" ? "video" : "post"}`}
                           </p>
                        </Dragger>
                     )}
                  </Form.Item>
               </Col>

               <Col lg={7}>
                  <InputComp required name="title" placeholder="Title" />
                  <InputComp
                     required
                     textarea
                     name="content"
                     placeholder="Description"
                  />

                  {params.type === "video" && (
                     <>
                        <CombineTabs
                           tabs={categories}
                           value={selectedCategory}
                           handleChange={handleChange}
                        />
                        <MultiSelect
                           data={
                              categories[selectedCategory]
                                 ?.evaluationattribute_set ?? []
                           }
                           values={subCategories}
                           onSelectItems={setSubCategories}
                        />
                     </>
                  )}
                  <InputComp
                     required
                     textarea
                     name="mention"
                     placeholder="Mentions"
                  />
                  <ButtonComponent text="CONFIRM" isLoading={isLoading} />
               </Col>
            </Row>
         </Form>
      </div>
   );
}
