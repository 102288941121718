import * as React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import Icons from "../../../constants/Icons";

export default function NewsfeedCard({ coach, label, sendInstructions, type }) {
   return (
      <Card
         sx={{
            display: "flex",
            justifyContent: "space-between",
            boxShadow: "0px 0px 26px #00000029",
            borderRadius: 1,
            margin: 2,
         }}>
         <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent
               sx={{ flex: "1 01 auto", alignItems: "center", paddingTop: 3 }}>
               <Typography
                  component="div"
                  variant="h5"
                  noWrap
                  style={{
                     fontFamily: "Nexa Bold",
                     color: "#ED6623",
                     fontSize: 21,
                  }}>
                  {label}
               </Typography>
               {coach && (
                  <Typography
                     component="div"
                     variant="h5"
                     noWrap
                     style={{
                        fontFamily: "Nexa Bold",
                        color: "#ED6623",
                        fontSize: 16,
                     }}>
                     By{" "}
                     <span
                        style={{
                           fontFamily: "Nexa Light",
                           color: "#696969",
                           fontSize: 16,
                        }}>
                        {coach}
                     </span>
                  </Typography>
               )}
               <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  style={{
                     fontFamily: "Nexa Light",
                     color: "#5A5A5A",
                     fontSize: 16,
                  }}>
                  10:30 - 11:30 am
               </Typography>
            </CardContent>
         </Box>

         <Box
            sx={{
               width: 50,
               backgroundColor: type === "event_point" ? "orange" : "red",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
            }}>
            <CardMedia
               component="img"
               sx={{
                  width: 25,
                  objectFit: "contain",
               }}
               image={type === "event_point" ? Icons.Calendar : Icons.Megaphone}
               alt="Live from space album cover"
            />
         </Box>
      </Card>
   );
}
