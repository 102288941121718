import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../../styles/auth.module.css";
import check from "../../../assets/imgs/signupCheck.svg";
import ButtonComponent from "../../../components/buttonComponent/ButtonComponent";

function PasswordSuccess() {
   const navigate = useNavigate();

   return (
      <div>
         <div className={styles.header}>
            <img
               src={check}
               className={styles.check}
               width={250}
               alt="password change success"
            />
            <div className={styles.authSuccessMsg} style={{ marginTop: 50 }}>
               Your password has been changed successfully
            </div>
         </div>
         <ButtonComponent
            text="SIGN IN"
            handlePress={() => navigate("/auth")}
         />
      </div>
   );
}

export default PasswordSuccess;
