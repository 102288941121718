import React from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { Form } from "antd";
import useHandleErrors from "../../../hooks/useHandleErrors";
import ButtonComponent from "../../buttonComponent/ButtonComponent";
import InputComp from "../../inputs/InputComp";
import { Services } from "../../../apis/Services/Services";
import SelectComponent from "../../inputs/SelectComponent";
import check from "../../../assets/imgs/signupCheck.svg";
import Text from "../../Text";
import Popup from "../Popup";

toast.configure();

export default function LinkAcademy({ isOpened, setIsOpened }) {
   const [setError] = useHandleErrors();
   const [activeStep, setActiveStep] = React.useState(0);

   const [isLoading, setIsLoading] = React.useState(false);
   const [academies, setAcademies] = React.useState([]);

   const [code, setCode] = React.useState(null);

   const handleAcademyCode = async (values) => {
      setIsLoading(true);
      await Services.get(
         `teams/?academy_code=${values.code}&page_size=50`,
         true
      )
         .then((res) => {
            if (res.results.length !== 0) {
               setCode(values.code);
               setAcademies(res.results);
               setActiveStep(1);
            } else {
               toast.error("Invalid academy code.");
            }
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };

   const handleSelectTeam = async (values) => {
      setIsLoading(true);
      let data = { ...values };
      data.academy = code;
      await Services.set(data, `academy-join-requests/`, true)
         .then((res) => {
            setActiveStep(2);
         })
         .catch(setError)
         .finally(() => setIsLoading(false));
   };

   return (
      <Popup isOpened={isOpened} setIsOpened={setIsOpened}>
         <Box
            sx={{
               minWidth: 300,
               width: 500,
               pt: 4,
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
            }}>
            {activeStep === 0 && (
               <>
                  <Text fSize={24} bold primary marginBottom>
                     Link to Academy
                  </Text>

                  <Form onFinish={handleAcademyCode} className="py-3">
                     <InputComp
                        required
                        name="code"
                        placeholder="Academy Code"
                     />
                     <ButtonComponent
                        text="CONFIRM"
                        btnStyle={{
                           fontSize: 16,
                           height: 40,
                        }}
                     />
                  </Form>
               </>
            )}

            {activeStep === 1 && (
               <>
                  <Text fSize={24} bold primary marginBottom>
                     Link to Academy
                  </Text>

                  <Form onFinish={handleSelectTeam} className="py-3">
                     <SelectComponent
                        required
                        name="requested_team"
                        placeholder="Select your team"
                        data={academies}
                     />
                     <ButtonComponent
                        isLoading={isLoading}
                        text="CONFIRM"
                        btnStyle={{
                           fontSize: 16,
                           height: 40,
                        }}
                     />
                  </Form>
               </>
            )}

            {activeStep === 2 && (
               <>
                  <Text fSize={24} bold primary marginBottom>
                     Link to Academy
                  </Text>

                  <div className="py-4 text-center">
                     <img
                        src={check}
                        className="w-50 my-2"
                        alt="academy pending"
                     />
                     <Text fSize={16}>Your request to academy is pending</Text>
                  </div>
               </>
            )}
         </Box>
      </Popup>
   );
}
