import { IconButton } from "@mui/material";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Bell from "../../assets/images/icons/bell.svg";
import Text from "../Text";
import styles from "./Header.module.css";

export default function Header({ text, popTo, type }) {
   return (
      <Row className="my-3 mx-0 align-items-center">
         <Col>
            <Text bold fSize={32} textClass={styles.headerTitle}>
               {text}
            </Text>
            {popTo === false && (
               <Text fSize={32} textClass={styles.headerTitle} secondary>
                  /{type}
               </Text>
            )}
         </Col>
         <Col lg={1} xs={3}>
            <IconButton
               aria-label="notification"
               // onClick={() => navigationTo("/app/add/post")}
               sx={{
                  m: 1,
               }}>
               <img src={Bell} width={25} height={25} alt="Notification icon" />
            </IconButton>
         </Col>
      </Row>
   );
}
