import React from "react";
import { Layout } from "antd";
import { Navigate, Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../components/sidebar/Sidebar";
import NewsFeed from "./Newsfeed";
import Academy from "./Academy";
import Schedule from "./Schedule";
import More from "./More";
import AddPost from "./Newsfeed/Add";
import AppContext from "../../contexts/AppContext";
import EditProfile from "./Profile/edit";
import PlayerProfile from "./Profile/player";
import CoachProfile from "./Profile/coach";

export default function Main() {
   const [checked, setChecked] = React.useState(false);
   const { appState } = React.useContext(AppContext);

   return (
      <Layout>
         <Row className="m-0">
            <Sidebar setCollapse={setChecked} />

            <Col className="px-0 py-4" lg={checked ? 11 : 10} xs={12}>
               <Container>
                  <Routes>
                     <Route path="/" element={<NewsFeed />} />
                     {appState.userType !== "adminuser" && (
                        <>
                           {appState.userType === "coachuser" && (
                              <Route
                                 path="/profile"
                                 element={
                                    <Navigate
                                       to={`/app/coaches/${appState.data?.id}?me=true`}
                                    />
                                 }
                              />
                           )}
                           {appState.userType === "playeruser" && (
                              <Route
                                 path="/profile"
                                 element={
                                    <Navigate
                                       to={`/app/players/${appState.data?.id}?me=true`}
                                    />
                                 }
                              />
                           )}
                           <Route
                              path="/players/:id"
                              element={<PlayerProfile />}
                           />
                           <Route
                              path="/coaches/:id"
                              element={<CoachProfile />}
                           />

                           <Route
                              path="/profile/edit"
                              element={<EditProfile />}
                           />
                        </>
                     )}
                     <Route path="/add/:type" element={<AddPost />} />

                     <Route path="/academy" element={<Academy />} />
                     <Route path="/schedule" element={<Schedule />} />
                     <Route path="/more" element={<More />} />
                  </Routes>
               </Container>
            </Col>
         </Row>
      </Layout>
   );
}
