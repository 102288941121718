import React from "react";
import styles from "../../styles/auth.module.css";
import check from "../../assets/imgs/signupCheck.svg";
import ButtonComponent from "../../components/buttonComponent/ButtonComponent";

function SignupSuccess() {
   return (
      <div>
         <div className={styles.header}>
            <img src={check} className={styles.check} alt="sign up success" />
            <div className={styles.authCodeTitle}>Congratulations!</div>
            <div className={styles.authSuccessMsg}>
               Your account has been activated
            </div>
         </div>

         <button className={styles.button2}>COMPLETE YOUR INFO</button>
         <ButtonComponent text="GO TO HOME" />
      </div>
   );
}

export default SignupSuccess;
